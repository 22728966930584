import Axios from 'axios';
import React, { Component } from 'react';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import { TRAINING_DATA_API, TRAINING_DATA_AUTH_TOKEN } from '../../utils/constant';

const SpeechSDK = require("microsoft-cognitiveservices-speech-sdk");
//Previous:
// const speechConfig = sdk.SpeechConfig.fromSubscription("32c9aabaa21c41678b3369b3ecfddd32", "centralindia");
// speechConfig.endpointId = "2f3ead9c-545e-444c-ac7d-01ec55e05390";
// speechConfig.speechRecognitionLanguage = "en-IN";
// speechConfig.enableDictation();
//Previous
let audioConfig;
let recognizer;

//Speech SDK Setter Function:
var speechConfig;
// var SpeechSDK=window['SpeechSDK'];

const credentialConfig = () => {
    // console.log(config)
    // if ((config[0].subscriptionkey.length > 0 && config[0].region.length > 0)) {
    //     speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
    //         config[0].subscriptionkey,
    //         config[0].region
    //     )
    // } else {
        speechConfig = SpeechSDK.SpeechConfig.fromSubscription("83008578aae847c59ad2d21591a36749", "centralindia")
        speechConfig.endpointId = "1243e3d0-df4e-4a94-9787-8962e87aea6f";
        speechConfig.speechRecognitionLanguage = "en-IN"
    // }
    // // debugger
    // if (config[0].endpointid) {
    //     speechConfig.endpointId = config[0].endpointid
    // }

    // if (config[0].language) {
    //     speechConfig.speechRecognitionLanguage = config[0].language
    // }

    // if (config[0].dictation) {
        speechConfig.enableDictation();
    // }
}
//Speech SDK Setter Function.

class MyHcSpeechRecognition extends Component {
    trainingData = {}

    constructor(props) {
        super(props)

        this.state = {
            speech: "",
            check: false,
            trainingDatafieldtypeSetter: {
                "chief-complaints": 0,
                "hpi": 1,
                "review-of-systems": 2
            },
            recognizerStatus: false,
            identifier: "",
        }
    }

    callTrainingDataApi(trainingData) {
        Axios({
            method: 'post',
            url: TRAINING_DATA_API,
            data: trainingData,
            headers: {
                Authorization: TRAINING_DATA_AUTH_TOKEN
            }
        });
    }

    componentDidMount() {
        // Axios.post("https://api1.voiceai.myhealthcare.life/creds")
        //     .then(response => {
        //         return response.data
        //     })
        //     .then(config => {
        //         // console.log(config)
                credentialConfig()
            // })
            // .catch(err => {
            //     // console.log(err)
            //     credentialConfig([{ subscriptionkey: "" }])
            // })


        this.subscription = speechService.status().subscribe(data => {
            // console.log("Subscription Data:", data)
            if (data.identifier === null) {
                return;
            }
            if (data.state) {
                this.setState((prevState) => ({

                    speech: data.initialText,
                    identifier: data.identifier
                }), () => {
                    if (recognizer) {
                        this.stopListening()
                    }
                    this.startListening(data.identifier);
                    if (this.props !== undefined && Object.keys(this.props).length > 0) {
                        console.log(this.props)
                        this.trainingData = {
                            booking_id: this.props.booking_id,
                            source: 2,
                            doctor_id: this.props.doctor_id,
                            step: this.props.trainingDataStep[this.state.identifier] ? 1 : 0,
                            input_text: this.props[this.state.identifier] ? this.props[this.state.identifier] : "",
                            field_type: this.state.trainingDatafieldtypeSetter[this.state.identifier]
                        }
                        if (this.trainingData["input_text"].length > 0) {
                            //call API
                            this.callTrainingDataApi(this.trainingData)
                            // console.log("Start Listening:", trainingData)
                        }
                    }
                });

            }
            if (!data.state) {
                if (recognizer && this.state.recognizerStatus) {
                    // console.log(recognizer)    
                    this.stopListening();
                    //Mic stops and data.initialText has required value of step 0
                    //Call API
                    //create object and call API
                    if (this.props !== undefined && Object.keys(this.props).length > 0) {
                        this.trainingData = {
                            booking_id: this.props.booking_id,
                            source: 2,
                            doctor_id: this.props.doctor_id,
                            step: 0,
                            input_text: (data.initialText === undefined || data.initialText === null) ? this.props[data.identifier] === undefined ? "" : this.props[data.identifier] : data.initialText,
                            field_type: this.state.trainingDatafieldtypeSetter[data.identifier]
                        }
                        this.callTrainingDataApi(this.trainingData)
                        // console.log("Stop Listening:", trainingData)
                    }
                }

            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    startListening(identifier) {
        this.setState({
            recognizerStatus: true
        }, async () => {
            // console.log(recognizer)
            // if (recognizer) {
            //     // console.log("In stop recognition")
            //     Promise.resolve(recognizer.stopContinuousRecognitionAsync())
            //     setTimeout(() => {
            //         recognizer.startContinuousRecognitionAsync()
            //     }, 100)
            // } else {
                // console.log("In else start recognition")
                audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
                recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
                await Promise.resolve(recognizer.startContinuousRecognitionAsync());
            // }

            function sentenceCase(inputString) {
                //regex operation
                inputString = inputString.replace(/centimeter(s?)|centimetre(s?)|CM/gi, "cm")
                    .replace(/( ?)(degree|degrees)?( ?)(celsius)/gi, '°C')
                    .replace(/( ?)(degree|degrees)?( ?)(fahrenheit)/gi, '°F')
                    .replace(/\ {2,}/g, ' ')
                    .replace(/\w+(?=\n)/g, inputString.match(/\w+(?=\n)/g)+ ".")
                    
                return inputString
            }

            recognizer.recognizing = (s, e) => {
                var temp = e.result.text + " "
                temp= sentenceCase(temp)
                if (identifier !== 'drop_list') {
                    let speechTxt = this.state.speech !== undefined ? this.state.speech : '';
                    speechTextService.init({ text: speechTxt + temp});
                }
                //  else {
                //     speechTextService.init({ text: temp});
                // }
            }
            recognizer.recognized = (s, e) => {
                if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech && e.result.text !== '') {
                    // var temp = e.result.text + " ";
                    var temp = /\n/g.test(e.result.text) ? e.result.text : e.result.text + " "
                    temp = sentenceCase(temp);
                    this.setState({
                        speech: this.state.speech + temp,
                    }, () => {
                        if(identifier === 'drop_list'){
                            speechTextService.init({ text: temp });
                        }else{
                            speechTextService.init({ text: this.state.speech });
                        } 
                    })
                }
                else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
                    console.log("NOMATCH: Speech could not be recognized.");
                }
            }
            recognizer.canceled = (s, e) => {
                console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason === SpeechSDK.CancellationReason.Error) {
                    console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    console.log("CANCELED: Did you update the subscription info?");
                }

                recognizer.stopContinuousRecognitionAsync();
            }
            recognizer.sessionStopped = (s, e) => {
                console.log("\n Session stopped event.");
                recognizer.stopContinuousRecognitionAsync();
            }
        })
    }

    async stopListening() {
        recognizer.stopContinuousRecognitionAsync()
        this.setState({
            // speech: "",
            recognizerStatus: false,
        });
    }

    render() {
        return (
            <></>
        )
    }

}

export default MyHcSpeechRecognition;
