import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import moment from 'moment';
import qs from 'qs';
import { CKEditor } from 'ckeditor4-react';

const { Option } = Select;

export default class ResultEntryEditors extends Component {
  constructor(props) {
    super(props);
    
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
  }

  render() {
    return (
      <>
        <div style={{}}>
        <div className="row">
          <h1 className="col mr-auto page-title">Result Entry :  {"("+this.props.PR_serviceName+")"}</h1>
        </div>

        <div className="w-100">
          <div className='row'>
            <div className='col-12'>
              <div className="banner-cardopd patient-card-opd patient-red-opd">
                <div className="row">
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">UHID : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.props.PR_registrationNo}</label>
                    <br />
                  </div>
                  <div className="col-3">
                    <label className="text-muted mb-0 mr-2">Name : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.props.PR_patientName}</label>
                  </div>
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">AGE/GENDER : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.props.PR_ageGender}</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted mb-0 mr-2">Encounter No : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.props.PR_encounterNo}</label>
                  </div>
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">Order No : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.props.PR_orderNo}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.props.isEditor ? "row" : "row d-none"}>
            <div className="col-12 mt-2">
              <div className="form-group filldata">
                <CKEditor
                  id="editor"
                  name="editor"
                  data={this.props.editorText != "" ? this.props.editorText : ""}
                  initData = {this.props.editorText != "" ? this.props.editorText : ""}
                  readOnly={this.props.PR_Code === "RP" ? true : false}
                  type="classic"
                  config={{
                    uiColor: '#ffffff',
                    allowedContent: true,
                    toolbarCanCollapse: true,
                    toolbarStartupExpanded: true,
                    removeButtons: 'Maximize',
                    height: 230,
                    removePlugins: 'elementspath',
                    resize_enabled: 'false',
                    extraPlugins: "dialogui,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard," +
                      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
                      "contextmenu,dialogadvtab,div,enterkey,entities,popup," +
                      "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
                      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
                      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
                      "newpage,pagebreak,pastefromword,pastetext,preview,print," +
                      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
                      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
                      "tabletools,templates,toolbar,undo,wysiwygarea,autogrow",
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div >
      </>
    )
  }
}
