import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import {
  LOADER_RED,
  PATIENT_CLINICAL_FORM_LIST,
  PATIENT_CLINICAL_FORM_PRINT
} from '../../../utils/constant';
import moment from 'moment';
import qs from 'qs';

const { Option } = Select;

export default class PatientClinicalForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      formListData : null,
      formPrintData : null,
      loader: true,
      showRxModal: false,
      rxPdfPath: null,
    }
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    await this.getPatientFormListData();
    
  }

  async getPatientFormListData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      registrationId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id
    }
    await Axios.post(PATIENT_CLINICAL_FORM_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let formListData =  res.status ? res.data.data : null;
      await this.setState({
        formListData: formListData,
        loader: false
      });
    })
  }

  async printPdfForm(result_data) {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounterId: selectedEncounterData.encounter_id,
      enterpriseId: selectedEncounterData.enterpriseId,
      facilityId: selectedEncounterData.facilityId,
      formid: result_data.formId,
      id: result_data.id,
      registrationId: selectedEncounterData.registrationId,
      withMultiEpisode: 0
    }
    await Axios.post(PATIENT_CLINICAL_FORM_PRINT, qs.stringify(PARAMS))
    .then(async res => {
      console.log(res);
      let formPrintData =  res.status ? res.data.data : null;
      if(formPrintData)
      {
        await this.setState({
          rxPdfPath : formPrintData.file || null,
          showFormModal: true
        });
      }
    })
  }

  PatientFormListLoad() {
    try {
      if ((!this.state.formListData || this.state.formListData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={6}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      return this.state.formListData.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{moment(result.enteredDate).add(330,'minutes').format('DD MMM YYYY | hh:mm A ')}</td>
            <td>{result.formName}</td>
            <td>{result.doctorName}</td>
            <td>{result.authorizedByName}</td>
            <td><a onClick={() => this.printPdfForm(result)}><img src={PDFImg} alt="view" width={15} /></a></td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientFormListLoad: ', err);
    }
  }

  
  render() {
    const { loader } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <div className='table-responsive scrollable_sub_table'>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th width="7%" className='px-1 py-2' style={{verticalAlign:'top'}}>S.NO</th>
                            <th width="18%" className='px-1 py-2' style={{verticalAlign:'top'}}>DATE TIME</th>
                            <th width="" className='px-1 py-2' style={{verticalAlign:'top'}}>FORM NAME</th>
                            <th width="17%" className='px-1 py-2' style={{verticalAlign:'top'}}>AUTHORED BY</th>
                            <th width="16%" className='px-1 py-2' style={{verticalAlign:'top'}}>AUTHORISED BY</th>
                            <th width="9%" className='px-1 py-2' style={{verticalAlign:'top'}}>PRINT</th>
                        </tr>
                    </thead>
                    <tbody>
                      {this.PatientFormListLoad()}
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        }

        <Modal
            title="Form Print"
            visible={this.state.showFormModal}
            footer={false}
            width={850}
            onCancel={() => { this.setState({ showFormModal: false, rxPdfPath: null }) }}
            style={{ top: 20 }}
          >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>
      </>
    )
  }
}
