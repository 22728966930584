import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Axios from "axios";
import qs from "qs";
import LS_SERVICE from '../../utils/localStorage';
import SoapFlow from "../../utils/soap";
import debounce from 'lodash/debounce';
import {
	ALLERGIES_DROPDOWN,
	ISALLOWEDITSOAP,
	OBJECTIVE_STORE,
	CONDITION_SEARCH,
	INFORMED_BY,
	LOADER,
	HANDLEVALIDANTMESSAGES,
	HANDLEVALIDANTMESSAGESPATIENTAGE,
	LOADER_BLACK,
	HISTORY_DELETE,
	DURATION_TYPE,
	MEDICATION_ON,
	LOADER_RED,
	OBJECTIVE_GETALL,
	PATIENT_DATA,
	SOAP_CONFIGURATION_LIST,
	HOSPITALDEFAULTS,
	VISIT_COUNT,
	NO_KNOWN_OBJECTIVE,
	ALLERGY_TAB_STATUS,
	ALLERGY_TAB_TYPE,
	DISABLED_FUTURE_DATES,
	USERTYPES,
	TOGGLE_PRACTICE,
	CONDITION_ADD_MASTER,
	ALLERGY_ADD_MASTER,
	FAMILY_HISTORY_LIST,
	SOCIALHX_LIST,
	FAMILY_HISTORY_DELETE,
	SOCIAL_HISTORY_DELETE,
	DISEASE_SEARCH,
	RELATIONSHIP_SEARCH,
	FAMILY_STORE,
	SOCIAL_HISTORY_UPDATE,
	EXISTING_CONDITION_LIST,
	SURGICAL_HISTORY_LIST,
	ALLERGIES_GETALL,
	BLOOD_GROUP_LIST,
	BLOOD_GROUP_UPADTE,
	BLOOD_GROUP,
	MEDICINES_GETALL_CURRENT_MEDICINES,
	PRESCRIPTION_DATA,
	SEARCH_DRUGS,
	ADD_DRUGS,
	PRESCRIPTION_STORE,
	CIMS_ALLERGY_INTERACTION_EXISTS,
    CIMS_ALLERGY_INTERACTION,
	CIMS_ALLERGY_SEARCH
} from "../../utils/constant";
import Moment from "react-moment";
import moment from "moment";
import { Form, Modal, Input, Radio, Button, Select, notification, Badge, Checkbox, DatePicker, Image, AutoComplete, Tooltip } from "antd";
import { jQueryLabels, AntSelectLabels, AntClearLabels, AntDisabledLabels, FixedCta, onlynumbers } from "../../utils/jQueryLabels";
import API_SERVICE from "../../utils/apiService";
import SubHeader from "../../components/subheader/subheader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { headerService$, practiceService } from "../../utils/rxjs-sharing";
import constants from "../../config/constants";
import { element } from "prop-types";
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

class ObjectiveComponentSS extends Component {

	constructor(props) {
		super(props);
		this.state = {
			patient: null,
			patientUrlData: null,
			allergies: null,
			conditions: null,
			surgical_histories: null,
			formModeEdit: false,
			habitStatusEdit: false,
			onMedication: "0",
			patient_age: '',
			loginRole: '',
			fromFieldId: -1,
			formFieldEntryId: null,
			surgicalModal: false,
			conditionModal: false,
			allergyModal: false,
			deleteModal: false,
			allergyAlreadyExistModal: false,
			history: "",
			number: null,
			duration: "",
			durationType: 3,
			allergy_duration: "",
			allergyDurationType: '',
			allergy_durationType: '',
			conditionList: [],
			condition_name: "",
			condition_selected: {},
			medication: "0",
			allergy_food: [],
			allergy_other: [],
			allergyList: [],
			allergy_selected: "",
			allergy_selected_data: {},
			allergy_active_tab: ALLERGY_TAB_STATUS[0],
			allergy_reaction_list: [],
			delete_text: "",
			deleteType: "",
			delete_id: "",
			spin_loading: false,
			formIsFetching: false,
			allergyAlreadyExistName: "",
			condition_duration_type: "",
			noallergies: false,
			noExistingCondition: false,
			noSurgicalHx: false,
			noSocialHx: false,
			noFamilyHx: false,
			totalVisit: 0,
			noDataModal: false,
			noDataModalText: "",
			isPracticing: false,
			isPracticingModal: false,
			allowEdit: true,
			dateFormat: "YYYY-MM-DD HH:mm:ss",
			timeFormat: "HH:mm:ss",
			calander_open: false,
			isNurseLoggined: false,
			continueModalForNurse: false,
			showAddConditionButton: false,
			disableAddConditionButton: false,
			showAddAllergyButton: false,
			disableAddAllergyButton: false,
			allergy_duration_type_required: false,
			condition_duration_type_required: false,
			surgical_duration_type_required: false,
			allergy_message: false,
			condition_message: false,
			surgical_message: false,
			family_message: false,
			social_message: false,
			isDeleteVisible: false,
			relationship_selected_data_id: '',
			DataLoaded: false,
			familyHistoryData: {},
			socialHxData: {},
			socialHxHabitData: {},
			socialHxQuantityData: {},
			socialHxRouteData: {},
			socialHxStatusData: {},
			socialHxSinceTimeData: {},
			socialHxFrequencyData: {},
			socialHxTableShow: false,
			patientInfo: {},
			removeItemId: '',
			removeFormName: '',
			removeItemMessage: '',
			addFamilyModal: false,
			addSocialModal: false,
			diseaseList: [],
			disease_selected_data: {},
			FamilyMemberList: [],
			FamilyMemberListLoaded: false,
			relationship_selected_data: {},
			BloodGroupList: {},
			isAppointment: '0',
			habits: '0',
			habitStatus: '1',
			habit: '0',
			status: '1',
			time: '0',
			frequency: '0',
			quantity_unit: '0',
			route: '0',
			actionType: '',
			actionText: '',
			dataLoading: this.props.dataLoading,
			patient_blood_group: '',
			ss_configuration: LS_SERVICE.get('ss_configuration'),
			sourcescreen: props.sourcescreen,
			consult_type: props.consult_type,
			drug_id: '',
			drug_name: '',
			formIsFetchingDrug: false,
			searchDrugs: [],
			showAddDrugButton: true,
			addedDrugsListsId: [],
			patient_prescription_drug_id:"",
			search_diseases: "",
			userId: LS_SERVICE.get("staff_id"),
			userName: LS_SERVICE.get("staff_name"),
			cancelledAllergy: false,
			cancelledSurgicalHistory: false,
			cancelledExistingConditions: false,
			cancelledSocialHistory: false,
			cancelledFamilyHistory: false,
			patientInactiveAllergies: [],
			patientInactiveConditions: [],
			inactiveSurgicalHistory: [],
			socialHxInactiveData: [],
			familyHistoryInactiveData: [],
			existingConditionDurationData:[],
			drug_id_Interaction_Modalurl:[],
			referenceType:0,
			allergyFlag:false,
			patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
			referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
			StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
			FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
			enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
			showAddDiseaseButton: false,
			cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
		};

		this.surgeryFormRef = React.createRef();
		this.conditionFormRef = React.createRef();
		this.allergyFormRef = React.createRef();
		this.bloodGroupRef = React.createRef();
		
		this.onClick = this.removeItem.bind(this);
		this.onClick = this.deleteConfirmation.bind();
		this.familyMemberFormRef = React.createRef();
		this.socialHxFormRef = React.createRef();
		this.onDrugssearch = this.onDrugssearch.bind();
		this.fetchAllergyData = debounce(this.fetchAllergyData, 700);
	}

	static getDerivedStateFromProps() {
		Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
		return true;
	}

	componentDidMount() {
		const hospital_id = LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0;
		const patient_id = LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0;
		const reference_id = LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0;

		const isPracticingHospitalId = LS_SERVICE.get("practicing")?.hospital_id;

		if (+isPracticingHospitalId === +hospital_id) {
			this.setState({
				isPracticing: true,
			});
		}

		const patientUrlData = {
			hospital_id,
			patient_id,
			qms_token_id: reference_id,
		};



		let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
		hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

		let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get("appointmentCompletedTime")[reference_id], "button") : true;

		this.setState(
			{
				hospital_id,
				patient_id,
				reference_id,
				patientUrlData,
				allowEdit,
			},
			() => {

				if (LS_SERVICE.has("hospitalConfigData") && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
					const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
          console.log(hospitalConfigData)
					let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == hospital_id);
          console.log(hospital_id)
          console.log(hospitalConfigObj)
					headerService$.soapConfig({
						soapConfig: hospitalConfigObj.opd_layout,
					}); // IMPORTANT TO HIT AS PER THE VIEW
					this.handleVisitCount();
					this.getPatientData();
					jQueryLabels();
					FixedCta();
					onlynumbers();
				} else {
					this.setState({ loginRole: 'nurse' }, () => {
						this.handleVisitCount();
						this.getPatientData();
						jQueryLabels();
						FixedCta();
						onlynumbers();
					});
				}
			});
	}



	getPatientData = (_) => {
	const reference_id = LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0;
    var reference_type = LS_SERVICE.get('reference_type');
		Axios.get(PATIENT_DATA({ reference_id, reference_type })).then((success) => {
			const patient = {
				...success.data.data.results,
				qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id,
			};
			//Check if nurse logged in and set doctor speciality
			if (this.state.isNurseLoggined) {
				var allDocSpecialities = LS_SERVICE.get('doctor_speciality_id');
				if (allDocSpecialities) {
					var returnValue = this.filterArray(success?.data?.data?.results?.doctor_id, allDocSpecialities);
					if (returnValue && returnValue.length > 0) {
						LS_SERVICE.set('speciality_id', returnValue[0]);
					}
				}
			}

			this.setState(
				{
					patient,
				},
				() => {
					this.handleAllergyDropDownData();
					this.handleBloodGroupListData();
					this.loadData();
					this.currentMeds();
				}
			);

			this.setState({
				patient_blood_group: success.data.data.results?.blood_group || '',
			});
			if (this.state.patient_blood_group != null) {
				this.bloodGroupRef?.current?.setFieldsValue({
					patient_blood_group: this.state.patient_blood_group
				})
			}
		});


	};

	currentMeds = async () => {
		let { patient } = this.state;
		const { patient_id, qms_token_id, hospital_id } = patient;
		const PARAMS = {
			patient_id,
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: qms_token_id,
			hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
		}
    PARAMS.store_id = LS_SERVICE.get("STORE_ID");
    PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
		await Axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id }))
			.then(success => {
				var addedDrugsList = [];
				let currentMedsList = success.data.data.details.current_medicines;
				currentMedsList.map((v, k) => { addedDrugsList.push(v.drug_id); });

				Axios.get(PRESCRIPTION_DATA(PARAMS))
					.then(success => {
						let { data } = success.data,
							drugId = data.details.map((v, k) => { addedDrugsList.push(v.drug_id) });
					}).catch(err => console.log(err.response));
				this.setState({
					addedDrugsListsId: addedDrugsList,
				})
			}).catch(err => console.log(err.response));

	};

	handleBloodGroupListData = async (_) => {
		const bloodGroupPromise = await Axios.get(BLOOD_GROUP_LIST);

		if (bloodGroupPromise.status) {
			this.setState({
				BloodGroupList: bloodGroupPromise.data.data.record,
			});
		}
	};

	deleteModalCancel = () => {
		this.setState({ isDeleteVisible: false })
	}

	loadData = () => {
		const { patient } = this.state;

		const familyHistorPromise = Axios.get(FAMILY_HISTORY_LIST + "/" + patient.patient_id);
		const socialHxPromise = Axios.get(SOCIALHX_LIST + "/" + patient.patient_id);
		const existingConditionPromise = Axios.get(EXISTING_CONDITION_LIST({ patient_id: patient.patient_id }));
		const surgicalHistoryPromise = Axios.get(SURGICAL_HISTORY_LIST({ patient_id: patient.patient_id }));
		const allergyHistoryPromise = Axios.get(ALLERGIES_GETALL({ patient_id: patient.patient_id }));
		Axios.all([familyHistorPromise, socialHxPromise, existingConditionPromise, surgicalHistoryPromise, allergyHistoryPromise])
			.then(Axios.spread((...responses) => {
				const responseOne = responses[0];
				const responseTwo = responses[1];
				const responseThree = responses[2];
				const responseFour = responses[3];
				const responseFive = responses[4];
				//  console.log(responseFive);

				if (responseOne.data.hasOwnProperty("data") === true) {
					console.log("responseOne.data.data.inactive_record", responseOne.data.data.inactive_record);
					this.setState({
						familyHistoryData: responseOne.data.data.record,
						noFamilyHx: responseOne.data.data.no_known_family,
						familyHistoryInactiveData: responseOne.data.data.inactive_record
					})
				}

				if (responseTwo.data.hasOwnProperty("data") === true) {
					this.setState({
						socialHxData: responseTwo.data.data.record,
						socialHxInactiveData: responseTwo.data.data.inactive_record,
						socialHxFrequencyData: responseTwo.data.data.frequency,
						socialHxHabitData: responseTwo.data.data.habbits,
						socialHxQuantityData: responseTwo.data.data.quantity,
						socialHxRouteData: responseTwo.data.data.route,
						socialHxStatusData: responseTwo.data.data.status,
						socialHxSinceTimeData: responseTwo.data.data.since_time,
						socialHxTableShow: true,
						noSocialHx: responseTwo.data.data.no_known_social,

					})
				}

				if (responseThree.data.hasOwnProperty("data") === true) {
					this.setState({
						conditions: responseThree.data.data.details.conditions.patientConditions,
						patientInactiveConditions: responseThree.data.data.details.conditions.patientInactiveConditions,
						noExistingCondition: responseThree.data.data.details.no_known_condition,
						existingConditionDurationData: responseThree.data.data.constants.pre_existing_condition_duration
					})
				}

				if (responseFour.data.data.details.hasOwnProperty("surgical_histories") === true) {
					
					this.setState({
						surgical_histories: responseFour.data.data.details.surgical_histories.patientSurgicalHistories,
						inactiveSurgicalHistory: responseFour.data.data.details.surgical_histories.patientInactiveSurgicalHistories,
						noSurgicalHx: responseFour.data.data.details.no_known_surgical_hsitory,
					})
				}

				if (responseFive.data.hasOwnProperty("data") === true) {
					
					this.setState({
						allergies: responseFive.data.data.details.allergies.patientAllergies,
						noallergies: responseThree.data.data.details.no_known_allergy,
						patientInactiveAllergies: responseFive.data.data.details.allergies.patientInactiveAllergies
					})
				}


				this.setState({
					patient_age: patient.age,
					DataLoaded: true,
				})

			}))

		this.fetchFamilyMemberData('');
	}

	Formclear = async () => {
		this.setState({
			allergyList: [],
			allergy_message: false,
		});
		this.allergyFormRef.current.resetFields();
		this.allergyFormRef.current.setFieldsValue({
			intensity: "",
			informedby: "1",
			allergyDurationType: "",
		});
	}

	handleInteractionOk     = () => { this.setState({ isModalInteractionVisible: false }) };
    handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false }, () => { this.Formclear();  }); };

	handleOnAllergyChange = (allergy_selected, props) => {

		const allergy_selected_data = {};
		Object.assign(allergy_selected_data, props.dataid);
		this.setState(
			{
				allergy_selected,
				allergy_selected_data,
				allergy: props.dataid.long
			},
			() => {
				AntSelectLabels();
			}
		);

		document.getElementById("reaction").focus();

		
		let {referenceId,StoreId,FacilityId,enterpriseId,patientId,referenceType} = this.state;
		let orederSetId         = 0;
		let careProtoColId      = 0;
		let drugIds             = 0;
		let addedAssessmentList = props.dataid.id;
		let medicineScreen      = 'medicineform';
		let showInteractionModal = false;  
		let allergyCimsGuid = props.dataid.allergies_cims_guid;
		let allergyCimsType = props.dataid.allergies_cims_type;

		if (this.state.cimsallow) {
			Axios.get(CIMS_ALLERGY_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList, allergyCimsGuid, allergyCimsType }))
				.then(success => {
					if (success.data.interaction) {
						showInteractionModal = true;
						this.setState(
							{
								drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, allergyFlag: true
							},
							() => this.setState({ isModalInteractionVisible: showInteractionModal })
						);
					} else {
						this.setState({ isModalInteractionVisible: false })
					}
				})
				.catch(err => console.log(err))
		} else {
			this.setState({ isModalInteractionVisible: false })
		}
	};

	handleAddButton = async (name) => {
		await this.setState({
		  [name]: false
		})
		if(name == "showAddAllergyButton"){
		  document.getElementById('reaction').focus();
		}
		if(name == "showAddConditionButton"){
		  document.getElementById('condition_duration').focus();
		}
		if(name == "showAddDiseaseButton"){
		  document.getElementById('family_member').focus();
		}
	}

	handleAddAllergy = async (type) => {
		const { hospital_id,allergy } = this.state;
		const allergyAddPromise = await Axios.post(ALLERGY_ADD_MASTER, { hospital_id, type, text: this.state.allergy });
		this.setState({
			disableAddAllergyButton: true
		});

		if (allergyAddPromise.status == 200) {
			if (type === 'drug') {
				let allergyList = this.state.allergyList;
				allergyList.push(allergyAddPromise.data);
				this.setState({
					disableAddAllergyButton: false,
					showAddAllergyButton: false,
					allergyList: allergyList,
				})
			}

			if (type === 'food') {
				let allergy_food = this.state.allergy_food;
				allergy_food.push(allergyAddPromise.data);
				this.setState({
					disableAddAllergyButton: false,
					showAddAllergyButton: false,
					allergy_food: allergy_food,
				})
			}

			if (type === 'others') {
				let allergy_other = this.state.allergy_other;
				allergy_other.push(allergyAddPromise.data);
				this.setState({
					disableAddAllergyButton: false,
					showAddAllergyButton: false,
					allergy_other: allergy_other,
				})
			}

			this.handleOnAllergyChange(allergyAddPromise.data.id, { children: allergyAddPromise.data.name, value: String(allergyAddPromise.data.id), dataid: allergyAddPromise.data });
		}
	}

	handleOnReactionChange = (e) => {
		//console.log(e.target.value);
	};

	

	handleAllergyDropDownData = async (_) => {
		const foodPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: "food" }));
		const otherPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: "others" }));

		if (foodPromise.status) {
			this.setState({
				allergy_food: foodPromise.data.result,
			});
		}

		if (otherPromise.status) {
			this.setState({
				allergy_other: otherPromise.data.result,
			});
		}
	};

	handleMaxLimit = (type) => {
		if (type == '') {
			type = 3;
		}
		const { patient } = this.state;
		if (patient !== null) {
			const sD = moment(patient.dob);
			const eD = moment();
			const maxVal = eD.diff(sD, DURATION_TYPE[type]);
			return maxVal;
		}
	};

	handleVisitCount = async () => {
		const { patient_id } = this.state.patientUrlData;
		const visitDetailsPromise = await Axios.post(VISIT_COUNT, {
			patient_id: patient_id,
		});
		if (visitDetailsPromise && visitDetailsPromise.data.visit) {
			this.setState({ totalVisit: visitDetailsPromise.data.visit.count });
		}
	};

	filterArray = (doc_id, doctor_specialities) => {
		var element = [];
		for (var i = 0; i < doctor_specialities.length; i++) {
			if (doctor_specialities[i].id == doc_id) {
				element = doctor_specialities[i].specialtiesIds;
				break;
			}
		}
		return element;

	};



	handleSoapFlow = (_) => {
		if (!LS_SERVICE.has("goToSoapPatientPageList") || LS_SERVICE.get("goToSoapPatientPageList").length === 0) {
			this.handleSoapInit();
		}
	};

	handleSoapInit = async (_) => {
		const { hospital_id } = this.state;

		const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
			hospital_id,
			staff_id: LS_SERVICE.get("staff_id"),
			speciality_id: LS_SERVICE.get("speciality_id") || null,
		});

		const pageList = [];
		const newList = [];

		const newSoapList = Object.values(soapList).map((data) => {
			return data.sort((a, b) => a.display_ordering - b.display_ordering);
		});

		Object.values(newSoapList)
			.flat()
			.forEach((en) => {
				if (en.display === "Y") {
					if (!pageList.includes(en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name)) {
						pageList.push(en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name);
						newList.push({
							url: en.alternate_url_format,
							parent_module: en.parent_module,
							module: en.parent_module === "subjective" || en.parent_module === "objective" ? en.parent_module : en.name,
						});
					}
				}
			});

		LS_SERVICE.set("goToSoapPatientUrl", newList);
		LS_SERVICE.set("goToSoapPatientPageList", pageList);
	};

	handleOnChange = (event) => {
		const { name, value } = event.target;

		this.setState({
			[name]: value,
		});

		this.setState({
			condition_duration_type_required: (name == 'condition_duration' && value != '' && value != null) ? true : false,
			allergy_durationType_required: (name == 'allergy_duration' && value != '' && value != null) ? true : false,
		});
		if (name == 'condition_duration') {
			this.conditionFormRef.current.setFieldsValue({
				condition_duration_type: (name == 'condition_duration' && value != '' && value != null) ? "3" : "",
			});
		}
		if (name == 'duration') {
			this.allergyFormRef.current.setFieldsValue({
				allergyDurationType: (name == 'duration' && value != '' && value != null) ? "3" : ""
			});
		}
	};

	onKeyPress = (e) => {
		// Allow only numbers (0-9), backspace, and the decimal point
		const allowedChars = /[0-9\b.]/;

		// Check if the pressed key is allowed
		if (!allowedChars.test(e.key)) {
			e.preventDefault();
		}
	}

	fetchAllergyData = async (search) => {
		await this.setState({
			allergyList: [],
			formIsFetching: true,
			allergy: search
		});
		if(this.state.cimsallow){
			if(search.length > 3){
				let params = {
					search,
					FacilityId: this.state.FacilityId
				}
				let allergyRes = await Axios.post(CIMS_ALLERGY_SEARCH,params);
				if(allergyRes.status == 200){
					this.setState({
						allergyList: allergyRes.data.data,
						formIsFetching: false,
						showAddAllergyButton: (search.length > 2 && allergyRes.data.data.length == 0) ? true : false,
					});
				}
			}
		}else{
			const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "drug" }));
			if (allergyDataPromise.status) {
				let { result } = allergyDataPromise.data;
				this.setState({
					allergyList: result,
					formIsFetching: !this.state.formIsFetching,
					showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
				});
			}
		}
	};

	fetchAllergyFoodData = async (search) => {
		await this.setState({
			allergy_food: [],
			formIsFetching: !this.state.formIsFetching,
			allergy: search
		});

		const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "food" }));

		if (allergyDataPromise.status) {
			let { result } = allergyDataPromise.data;
			this.setState({
				allergy_food: result,
				formIsFetching: !this.state.formIsFetching,
				showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
			});
		}
	};

	fetchAllergyOthersData = async (search) => {
		await this.setState({
			allergy_other: [],
			formIsFetching: !this.state.formIsFetching,
			allergy: search
		});

		const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "others" }));

		if (allergyDataPromise.status) {
			let { result } = allergyDataPromise.data;
			this.setState({
				allergy_other: result,
				formIsFetching: !this.state.formIsFetching,
				showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
			});
		}
	};

	handleAllergyTab = (e, TAB) => {
		e.preventDefault();

		this.setState(
			{
				allergy_active_tab: TAB,
				allergy_selected: "",
				allergy_selected_data: {},
				showAddAllergyButton: false
			},
			() => {
				this.allergyFormRef.current.setFieldsValue({
					allergy_selected: "",
					reaction: [],
					intensity: "",
					informedby: "1",
				});
			}
		);
	};

	handleSurgeryAddSubmit = async (_) => {
		const { history, remarks } = this.surgeryFormRef.current.getFieldValue();
		const { patient, fromFieldId, spin_loading } = this.state;
		const { qms_token_id, patient_id } = patient;

		const PARAMS = {
			type: "history",
			objective_details: [
				{
					history,
					remarks: remarks ? remarks : null,
					id: fromFieldId,
				},
			],
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: qms_token_id,
			patient_id: patient_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
		};

		this.setState({
			spin_loading: true,
		});

		const surgerySubPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS));

		if (surgerySubPromise.data.status) {
			const { data } = surgerySubPromise;
			this.loadData();
			this.props.initialSetup();
			if (fromFieldId > 0) {
				this.setState({
					surgical_message: false,
				});
				notification.success({
					message: 'Surgical HX Updated Succesfully',
					placement: "topRight",
				});
				this.handleModalPopup(null, "surgicalModal");
			}
			else {
				if (this.surgeryFormRef.current !== null) {
					this.surgeryFormRef.current.resetFields();
					this.surgeryFormRef.current.setFieldsValue({
						durationType: "3",
					});
				}
				this.setState({
					surgical_message: true,
				});
				setTimeout(() => {
					this.setState({ surgical_message: false });
				}, 3000);
			}
			this.setState({
				spin_loading: false,
			});
		}
	};

	handleConditionAddSubmit = async (_) => {
		const { condition_name, medication, medication_name, condition_duration, condition_duration_type } =
			this.conditionFormRef.current.getFieldValue();
		const { patient, fromFieldId, condition_selected, spin_loading, formFieldEntryId, formModeEdit } = this.state;
		const { qms_token_id, patient_id } = patient;
		let medicineType ='F'
		if (this.state.medication !== undefined && this.state.medication !== "" && this.state.medication !== "0") {
			if (this.state.drug_id == '' || this.state.drug_id == null) {
				let emailParams = {
					drug_name: this.state.drug_name,
				}
         
				await Axios.post(ADD_DRUGS, {
					hospital_id: LS_SERVICE.get('slot_hospital_id'),
					hospital_master: LS_SERVICE.get('slot_hospital_master'),
					name: this.state.drug_name,
					isCurrentMeds: true,
					emailParams: emailParams
				})
					.then(success => {
						this.setState({
							drug_id: success.data.data.id,
						});
						medicineType='N'
					}).catch(err => {
						console.log(err);
					})
			}
			if (!this.state.drug_id == '' && !this.state.drug_name == '') {
				const medicine_data = {
					drug_id: this.state.drug_id,
					drug_name: this.state.drug_name,
					prescription_drug_id: -1,
					active: 'Y',
					isOnlyCurrentMeds: 'Y',
					medicine_type:medicineType
				}
				const PRESCRIPTION_PARAMS = {
					reference_type: LS_SERVICE.get('reference_type'),
					reference_id: qms_token_id,
					patient_id,
					medicine_data: [medicine_data],
					isAddedExistingMeds: 'Y',
				};
			await Axios.post(PRESCRIPTION_STORE, qs.stringify(PRESCRIPTION_PARAMS)).then(success => {
				  let	patient_prescription_drug_id = success.data.storedDrugObject;
					this.setState({
						drug_id: '',
						drug_name: '',
						patient_prescription_drug_id:patient_prescription_drug_id[0].id
					})
					this.currentMeds();
				})
					.catch(err => console.log(err))
			}
		}
		const PARAMS = {
			type: "condition",
			objective_details: [
				{
					condition: formModeEdit ? formFieldEntryId : condition_selected.id,
					condition_name,
					condition_duration,
					condition_duration_type,
					medication,
					medicine_name: medication == 1 ? medication_name : "",
					id: fromFieldId,
					patient_prescription_drug_id:this.state.patient_prescription_drug_id,
				},
			],
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: qms_token_id,
			patient_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
		};

		this.setState({
			spin_loading: true,
		});
		const conditionDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS));

		if (conditionDataPromise.data.status) {
			const data = conditionDataPromise;
			this.loadData();
			this.props.initialSetup();
			if (fromFieldId > 0) {
				this.setState({
					condition_message: false,
				});
				this.handleModalPopup(null, "conditionModal");
				notification.success({
					message: 'Condition Updated Succesfully',
					placement: "topRight",
				});
			}
			else {
				this.conditionFormRef.current.resetFields();
				this.conditionFormRef.current.setFieldsValue({
					condition_duration_type: "",
					condition_name: "",
					medication: "0",
				});
				this.setState({
					condition: "",
					condition_message: true,
				});
				setTimeout(() => {
					this.setState({ condition_message: false });
				}, 3000);
			}
			this.setState({
				spin_loading: false,
				condition_selected: {},
				patient_prescription_drug_id:""
			});
		}
	};

	handleOnConditionChange = (condition_name, props) => {
		const condition_selected = {};
		Object.assign(condition_selected, props.dataid);
		this.setState({
			condition_name: props.dataid.name,
			condition_selected,
			condition: props.dataid.name
		});

		this.conditionFormRef.current.setFieldsValue({
			condition_name: props.dataid.name,
		});

		document.getElementById("condition_duration").focus();
		AntSelectLabels();
	};

	fetchConditionData = async (search) => {
		await this.setState({
			conditionList: [],
			formIsFetching: !this.state.formIsFetching,
			condition: search,
		});

		const conditionDataPromise = await Axios.post(CONDITION_SEARCH, qs.stringify({ search }));

		if (conditionDataPromise.status) {
			let { result } = conditionDataPromise.data;
			this.setState({
				conditionList: result,
				formIsFetching: !this.state.formIsFetching,
				showAddConditionButton: (search.length > 2 && result.length == 0) ? true : false,
			});
		}
	};

	handleAddCondition = async () => {
		const conditionAddPromise = await Axios.post(CONDITION_ADD_MASTER, { text: this.state.condition });
		this.setState({
			disableAddConditionButton: true
		});

		if (conditionAddPromise.status == 200) {
			let conditionList = this.state.conditionList;
			conditionList.push(conditionAddPromise.data);
			this.setState({
				disableAddConditionButton: false,
				showAddConditionButton: false,
				conditionList: conditionList,
			})
			this.handleOnConditionChange(conditionAddPromise.data.id, { children: conditionAddPromise.data.name, value: String(conditionAddPromise.data.id), dataid: conditionAddPromise.data });
		}
	}

	handleAntFormOnChange = (d) => {
		let { name, value } = d.currentTarget.hasOwnProperty("name") ? d.currentTarget : d.target;

		this.setState({
			[name]: value,
		});
	};

	handleAllergyAddSubmit = async (form_data) => {
		const { reaction, intensity, informedby, allergy_duration, allergyDurationType } = this.allergyFormRef.current.getFieldValue();
		const allergy_selected = this.state.allergy;

		const {
			patient,
			allergy_selected_data,
			allergy_active_tab,
			fromFieldId,
			spin_loading,
			formModeEdit,
			formFieldEntryId,
			allergies,
			allergyAlreadyExistName,
		} = this.state;
		const { qms_token_id, patient_id } = patient;

		if (allergies?.find((all) => all.allergy_name.toLowerCase() === allergy_selected?.toLowerCase()) !== undefined && !formModeEdit) {
			if (allergyAlreadyExistName === "") {
				const data = allergies?.find((all) => all.allergy_name.toLowerCase() === allergy_selected.toLowerCase());
				this.setState(
					{
						allergyAlreadyExistName: allergy_selected,
						allergy_selected_data: { ...data },
					},
					() => this.handleModalPopup(null, "allergyAlreadyExistModal")
				);
				return;
			}
		}

		if (reaction?.toString().length > 250) {
			notification.error({
				message: "Reaction limit exceeded",
				placement: "topRight",
			});
			return;
		}

		let objectiveObj = {
			allergy: formModeEdit ? formFieldEntryId : allergy_selected_data.id,
			allergy_name: allergy_selected_data.long ? allergy_selected_data.long : form_data.allergy_selected,
			allergy_type: ALLERGY_TAB_TYPE[allergy_active_tab],
			informed_by: informedby,
			reaction: reaction?.toString(),
			intensity,
			duration: allergy_duration,
			durationType: allergyDurationType,
			id: fromFieldId,
		}

		//if allergy is of type drug and cims is true
		if(ALLERGY_TAB_TYPE[allergy_active_tab] == 0 && this.state.cimsallow){
			objectiveObj["is_cims"] = "Y";
		}else{
			objectiveObj["is_cims"] = "N";
		}

		const PARAMS = {
			type: "allergy",
			objective_details: [
				[
					objectiveObj
				],
			],
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: qms_token_id,
			patient_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
		};

		this.setState({
			spin_loading: true,
		});

		const allergyPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS)).catch((err) => {
			const res = err.response;
			if (res.status === 500) {
				notification.error({
					message: err?.response?.data?.err,
					placement: "topRight",
				});
				this.setState({
					spin_loading: false,
				});
			}
		});

		if (allergyPromise?.status) {
			this.loadData();
			this.props.initialSetup();
			const { data } = allergyPromise;
			if (fromFieldId > 0) {
				this.setState({
					allergy_message: false,
				});
				notification.success({
					message: 'Allergy Updated Succesfully',
					placement: "topRight",
				});
				this.handleModalPopup(null, "allergyModal");
			}
			else {
				if (this.allergyFormRef.current !== null) {
					this.allergyFormRef.current.resetFields();
				}
				this.setState({
					allergyList: [],
					allergy_message: true,
				});
				setTimeout(() => {
					this.setState({ allergy_message: false });
				}, 3000);
			}
			this.setState({
				spin_loading: false,
				allergyAlreadyExistName: "",
				allergy_selected_data: {}
			});
		}
	};

	handleDeletePopSubmit = async (e) => {
		e.preventDefault();

		let { type, deleteType, delete_id, spin_loading, userName, userId } = this.state;

		type = deleteType === "surgical" ? "surgical-history" : deleteType === "condition" ? "condition" : "allergy";

		this.setState({
			spin_loading: true,
		});

		const deletePromise = await Axios.get(HISTORY_DELETE({ type, id: delete_id, userName, userId }));

		if (deletePromise.status) {
			let { data } = deletePromise;
			this.handleModalPopup(null, "deleteModal");
			this.props.initialSetup();
			this.loadData();
			notification.success({
				message: data.message,
				placement: "topRight",
			});
			this.setState({
				spin_loading: false,
			});
		}
	};

	radioChange = (event, state) => {
		this.setState({
			[state]: event.target.value,
		});
	};

	// For Delete Item Modal open
	removeItem(event, id, formName) {
		event.preventDefault();

		const { isPracticing } = this.state;

		if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
			if (!isPracticing) {
				this.setState({
					isPracticingModal: true
				})
				return;
			}
		}

		this.setState({
			isDeleteVisible: true,
			removeItemId: id,
			removeFormName: formName
		});

		// For Family Hx
		if (formName === "Family Hx") {
			this.setState({ removeItemMessage: "Are you sure you want to delete family history?" })
		}

		// For Social Hx
		if (formName === "Social Hx") {
			this.setState({ removeItemMessage: "Are you sure you want to delete social history?" });
		}
	}

	// For Modal popup
	handleFamilySocialModalPopup = (e, popup, data = null, actionType = '') => {
		if (e !== null) e.preventDefault();

		const { isPracticing } = this.state;

		if(popup == "addFamilyModal"){
			this.setState({showAddDiseaseButton: false});
		}

		if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
			if (!isPracticing) {
				this.setState({
					isPracticingModal: true
				})
				return;
			}
		}

		this.setState({
			[popup]: !this.state[popup]
		}, () => {
			this.setState({
				habit: '0',
				habitStatus: '1',
				formModeEdit: false,
				habitStatusEdit: false,
				fromFieldId: -1,
				actionText: "Add",
				family_message: false,
				social_message: false,
				actionType
			}, () => {

				const { addFamilyModal, addSocialModal } = this.state;

				if (addFamilyModal) {
					this.familyMemberFormRef.current.resetFields();
					this.setState({
						disease_id: '',
						disease_name: '',
						relationship_selected_data_id: undefined
					})


					if (actionType === "editFamily") {

						this.setState({
							actionText: "Update",
							formModeEdit: true,
							disease_id: data.diseaseId,
							disease_name: data.diseaseName,
							fromFieldId: data.id,
							relationship_selected_data_id: data.relationshipId
						})

						this.familyMemberFormRef.current.resetFields();
						this.familyMemberFormRef.current.setFieldsValue({
							disease_name: data.diseaseName
						})
					}

					setTimeout(function () {
						AntSelectLabels();
					}, 100);
				}

				if (addSocialModal) {
					this.socialHxFormRef.current.resetFields();

					if (actionType === "editSocial") {
						this.setState({
							actionText: "Update",
							formModeEdit: true,
							fromFieldId: data.id,
							habit: JSON.stringify(data.details),
							time: JSON.stringify(data.time)
						})

						// this.socialHxFormRef.current.resetFields();
						this.socialHxFormRef.current.setFieldsValue({
							habit: JSON.stringify(data.details),
							status: JSON.stringify(data.options),
							since: JSON.stringify(data.since),
							time: JSON.stringify(data.time),
							frequency: JSON.stringify(data.frequency),
							quantity: JSON.stringify(data.quantity),
							quantity_unit: JSON.stringify(data.quantityUnit),
							drug_name: data.drugName,
							route: JSON.stringify(data.route)
						})

						if (JSON.stringify(data.options) === '0') {
							this.setState({ habitStatusEdit: true });
							this.socialHxFormRef.current.setFieldsValue({
								since: '',
								time: '0',
								frequency: '0',
								quantity: '',
							})

							if (JSON.stringify(data.details) === '0') {
								this.socialHxFormRef.current.setFieldsValue({
									quantity_unit: '0'
								})
							}
							if (JSON.stringify(data.details) === '1' || JSON.stringify(data.details) === '3') {
								this.socialHxFormRef.current.setFieldsValue({
									quantity_unit: '5'
								})
							}
							if (JSON.stringify(data.details) === '2') {
								this.socialHxFormRef.current.setFieldsValue({
									quantity_unit: '7'
								})
							}
						}
					} else {
						this.socialHxFormRef.current.setFieldsValue({
							habit: "0",
							time: "0",
							status: "1",
							frequency: "0",
							quantity_unit: "0",
							route: "0",
						})
					}
				} else {
					this.setState({
						time: "0"
					})
				}
			})
		})

		setTimeout(function () {
			AntDisabledLabels()
		}, 100);
	}

	handleReset = (e, popup) => {
		if (e !== null) e.preventDefault();
		this.setState({
			spin_loading: false,
			formModeEdit: false,
			fromFieldId: -1,
			medication: "0",
			delete_id: "",
			actionText: "Add",
		});
		if (popup === "familyHistory") {
			this.setState({
				family_message: false,
				disease_id: '',
				disease_name: '',
				relationship_selected_data_id: undefined
			});
			this.familyMemberFormRef.current.resetFields();
		}
		else if (popup === "socialHistory") {
			this.setState({
				habit: '0',
				habitStatus: '1',
				habitStatusEdit: false,
				social_message: false,
			});
			this.socialHxFormRef.current.resetFields();
			this.socialHxFormRef.current.setFieldsValue({
				habit: "0",
				time: "0",
				status: "1",
				frequency: "0",
				quantity_unit: "0",
				route: "0",
			});
		}
		else if (popup === "surgicalHistory") {
			this.setState({
				surgical_message: false,
			});
			this.surgeryFormRef.current.resetFields();
			this.surgeryFormRef.current.setFieldsValue({
				durationType: "3",
			});
		}
		else if (popup === "conditions") {
			this.setState({
				condition: "",
				condition_message: false,
			});
			this.conditionFormRef.current.resetFields();
			this.conditionFormRef.current.setFieldsValue({
				condition_duration_type: "",
				condition_name: "",
				medication: "0",
			});
		}
		else if (popup === "allergies") {
			this.setState({
				allergyList: [],
				allergy_message: false,
			});
			this.allergyFormRef.current.resetFields();
			this.allergyFormRef.current.setFieldsValue({
				intensity: "",
				informedby: "1",
				allergyDurationType: "",
			});
		}
	}

	handleModalPopup = (e, popup, data = null, deleteType = "") => {


		
		if (e !== null) e.preventDefault();
		const { isPracticing } = this.state;

		if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
			if (!isPracticing && popup !== "chartModal" && popup !== "keyFindingChartModal") {
				this.setState({
					isPracticingModal: true,
				});
				return;
			}
		}

		this.setState(
			{
				[popup]: !this.state[popup],
			},
			() => {
				const { allergyModal } = this.state;
				const delete_text =
					deleteType === "surgical"
						? data.reason
						: deleteType === "condition"
							? data.condition_name
							: deleteType === "allergy"
								? data.allergy_name
								: "";

				if (!allergyModal) {
					if (this.allergyFormRef.current !== null) {
						this.allergyFormRef.current.resetFields();
					}
					this.setState({
						allergyList: []
					});
				}

				this.setState(
					{
						spin_loading: false,
						formModeEdit: false,
						fromFieldId: -1,
						medication: "0",
						delete_text,
						deleteType,
						delete_id: data !== null ? data.id : "",
						allergy_message: false,
						condition_message: false,
						surgical_message: false,
					},
					() => {
						let { surgicalModal, conditionModal, allergyModal, allergyAlreadyExistModal, allergyAlreadyExistName } =
							this.state;

						if (surgicalModal) {
							this.surgeryFormRef.current.resetFields();
						} else if (conditionModal) {
							this.conditionFormRef.current.resetFields();
							this.conditionFormRef.current.setFieldsValue({
								condition_duration_type: "",
								medication: "0",
							});
						} else if (allergyModal) {
							if (allergyAlreadyExistName === "") {
								this.allergyFormRef.current.resetFields();
								this.allergyFormRef.current.setFieldsValue({
									intensity: "",
									informedby: "1",
									allergyDurationType: "",
								});
							}
						}

						if (data !== null) {
							if (popup === "surgicalModal") {
								if (surgicalModal) {
									this.setState({
										formModeEdit: true,
										fromFieldId: data.id,
									});
									this.surgeryFormRef.current.setFieldsValue({
										history: data.reason,
										remarks: data.remarks
									});
								}
							} else if (popup === "conditionModal") {
								if (conditionModal) {
									this.setState({
										formModeEdit: true,
										fromFieldId: data.id,
										medication: (data.onMedication) ? JSON.stringify(data.onMedication) : '',
										formFieldEntryId: data.conditionId,
										condition: data.condition_name
									});
									this.conditionFormRef.current.setFieldsValue({
										condition_name: data.condition_name,
										condition_duration: data.duration ? JSON.stringify(data.duration) : '',
										condition_duration_type: JSON.stringify(data.durationType),
										medication: JSON.stringify(data.onMedication),
										medication_name: data.medicineName,
									});
								}
							} else if (popup === "allergyModal") {
								if (allergyModal) {
									this.setState({
										formModeEdit: true,
										fromFieldId: data.id,
										allergy_selected_data: data,
										allergy_active_tab: data.allergyType.toUpperCase() === "DRUG" ? "DRUGS" : data.allergyType.toUpperCase(),
										formFieldEntryId: data.allergyId,
										allergy_duration: data.duration,
										allergyDurationType: data.durationType,
										allergy: data.allergy_name
									});

									if (allergyAlreadyExistName === "") {
										this.allergyFormRef.current.setFieldsValue({
											allergy_selected: data.allergy_name,
											reaction: data.reaction ? data.reaction?.split(",") : [],
											intensity: (data.intensity || data.intensity == 0) ? JSON.stringify(data.intensity) : '',
											informedby: JSON.stringify(data.informedBy),
											allergy_duration: data.duration == null ? null : JSON.stringify(data.duration),
											allergyDurationType: JSON.stringify(data.durationType),
										});
									}
								}
							} else if (popup === "allergyAlreadyExistModal") {
								this.setState(
									{
										formModeEdit: true,
										fromFieldId: data.id,
										formFieldEntryId: data.allergyId,
										allergy_active_tab: data.allergyType.toUpperCase() === "DRUG" ? "DRUGS" : data.allergyType.toUpperCase(),
									},
									() => this.handleAllergyAddSubmit()
								);
							}
						}

						if (!allergyAlreadyExistModal) {
							if (allergyAlreadyExistName !== "") {
								this.setState({
									allergyAlreadyExistName: "",
								});

								if (allergyModal) {
									this.setState(
										{
											allergy_active_tab: this.state.allergy_active_tab,
											allergy_selected: "",
											allergy_selected_data: {},
										},
										() => {
											if (this.allergyFormRef.current !== undefined) {
												this.allergyFormRef.current.setFieldsValue({
													allergy_selected: "",
													reaction: [],
													intensity: "",
													informedby: "1",
												});
											}
										}
									);
								}
							}
						}
						if (!conditionModal) {
							this.setState({
								condition: '',
								showAddConditionButton: false,
								disableAddConditionButton: false,
							});
						}

						if (!allergyModal) {
							this.setState({
								allergy: '',
								showAddAllergyButton: false,
								disableAddAllergyButton: false,
							});
						}

						AntDisabledLabels();
						AntSelectLabels();

						// if (clear) {
						//     this.setState({
						//         spin_loading: false
						//     })
						// }
					}
				);
			}
		);
	}; 

	handleOnCheckBoxChange = async (e, checkeBox) => {
		const { name } = e.target;
		const { patient_id } = this.state.patient;
		// const { allergies, conditions, surgical_histories } = this.state;

		console.log(this.state[checkeBox].length);

		if (this.state[checkeBox].length === 0) {
			this.setState({
				[name]: !this.state[name],
			});
		} else {
			e.preventDefault();
			return;
		}

		let type =
			name == "noallergies" ? "allergy" : name == "noExistingCondition" ? "existing-condition" : name == "noSocialHx" ? "social-history" : name == "noFamilyHx" ? "family-history" : name == "noSurgicalHx" ? "surgical-history" : "";

		await Axios.post(NO_KNOWN_OBJECTIVE, {
			objectiveType: type,
			objectiveStatus: !this.state[name],
			patientId: patient_id,
		});
	};

	handleObjectiveContinue = () => {
		const { patient, patientUrlData } = this.state;
		const { patient_id, qms_token_id, hospital_id } = patientUrlData;

		const isTodayVisitArr = LS_SERVICE.has("isTodayVisit") ? LS_SERVICE.get("isTodayVisit") : [];
		const pageListArr = LS_SERVICE.get("goToSoapPatientPageList");
		let index = pageListArr.indexOf("objective");
		let childDevIndex = pageListArr.indexOf("child_development");
		let mensuralHxIndex = pageListArr.indexOf('mensural_hx');
		let sexualHxIndex = pageListArr.indexOf('sexual_hx');
		let obstetricsHxIndex = pageListArr.indexOf('obstetrics_hx');
		let physicalExaminationIndex = pageListArr.indexOf('physical_examination');

		const isPaediatrics = LS_SERVICE.get("isPaediatrics") ? LS_SERVICE.get("isPaediatrics") : false;


		const isObsGynaePatient = LS_SERVICE.get('isObsGynaePatient') ? LS_SERVICE.get('isObsGynaePatient') : false;
		const isObsGynaeDoctor = LS_SERVICE.get('isObsGynaeDoctor') ? LS_SERVICE.get('isObsGynaeDoctor') : 0;
		if (isObsGynaePatient == true && isObsGynaeDoctor == 1) {
			index++;
		} else {
			/* if (isObsGynaeDoctor == 1) { */
			if (mensuralHxIndex != -1) {
				index++;
			}
			if (sexualHxIndex != -1) {
				index++;
			}
			if (obstetricsHxIndex != -1) {
				index++;
			}
			if (physicalExaminationIndex != -1) {
				index++;
			}
			//index++;
			/* } else { */
			if (childDevIndex == -1) {
				let vaccChartIndex = pageListArr.indexOf("vaccination_chart");
				if (vaccChartIndex == -1) {
					index++;
				} else {
					index = index + 2;
				}
			} else {
				if (isPaediatrics) {
					index++;
				} else {
					let vaccChartIndex = pageListArr.indexOf("vaccination_chart");
					if (vaccChartIndex == -1) {
						index = index + 2;
					} else {
						index = index + 3;
					}
				}
			}
			//}
		}
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
		if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
			let soapurl = LS_SERVICE.get("goToSoapPatientUrl")[index].url;
			soapurl = soapurl.replace("HOSPITALID", hospital_id);
			soapurl = soapurl.replace("REFID", qms_token_id);
			soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
			soapurl = soapurl.replace("PATID", patient_id);
			this.props.history.push({
				pathname: soapurl,
				state: { patient },
			});
		} else {
			this.props.history.push({
				pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
				state: { patient },
			});
		}
	};

	handleObjectiveSubmit = () => {
		const { allergies, conditions, surgical_histories, socialHxData, familyHistoryData, totalVisit, noExistingCondition, noallergies, noSurgicalHx, noSocialHx, noFamilyHx, isPracticing, patientUrlData, patient, socialHxInactiveData } = this.state;

		const { hospital_id } = patientUrlData;

		if (this.state.loginRole === "nurse") {
			this.props.history.push({
				pathname: `/patient-queue/${hospital_id}`,
				state: { patient },
			});
		}

		if (!this.state.allowEdit) {
			this.handleObjectiveContinue();
			return;
		}

		if (!isPracticing) {
			this.handleModalPopup(null, "isPracticingModal");
			return;
		}

		if (parseInt(totalVisit) <= 1) {
			let allergyText = "";
			if (allergies.length == 0) {
				allergyText = "Allergies";
			}

			let existingText = "";
			if (conditions.length == 0) {
				existingText = "Existing Condition";
			}

			let historyText = "";
			if (surgical_histories.length == 0) {
				historyText = "History";
			}

			let socialText = "";
			if (socialHxData.length == 0) {
				socialText = "SocialHx";
			}

			let familyText = "";
			if (familyHistoryData.length == 0) {
				familyText = "FamilyHx";
			}

			if ((allergyText !== "" && noallergies == 0) || (existingText != "" && noExistingCondition == 0) || (historyText != "" && noSurgicalHx == 0) || (socialText != "" && noSocialHx == 0) || (familyText != "" && noFamilyHx == 0)) {
				var htmlTxt = "";
				if (allergyText !== "" && noallergies == 0) htmlTxt += allergyText + ", ";
				if (existingText !== "" && noExistingCondition == 0) htmlTxt += existingText + ", ";
				if (historyText !== "" && noSurgicalHx == 0) htmlTxt += historyText;
				if (socialText !== "" && noSocialHx == 0) htmlTxt += socialText;
				if (familyText !== "" && noFamilyHx == 0) htmlTxt += familyText;

				htmlTxt += " are not entered. Do you want to proceed?";
				htmlTxt = htmlTxt.replace(/,([^,]*)$/, " and$1");
				this.setState({ noDataModalText: htmlTxt });
				this.setState({ noDataModal: true });
			} else {
				this.handleObjectiveContinue();
			}
		} else {
			this.handleObjectiveContinue();
		}
	};

	handleObjectiveContinueForNurse = () => {
		const { patient, patientUrlData } = this.state;
		const { patient_id, qms_token_id, hospital_id } = patientUrlData;

		SoapFlow.hospital(hospital_id)
		const mensural_hx = SoapFlow.showElement('obsgynae', 'mensural_hx');
		const sexual_hx = SoapFlow.showElement('obsgynae', 'sexual_hx');
		const obstetrics_hx = SoapFlow.showElement('obsgynae', 'obstetrics_hx');
		const physical_examination = SoapFlow.showElement('obsgynae', 'physical_examination');
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
		if (LS_SERVICE.get("isObsGynaePatient") && LS_SERVICE.get('isObsGynaeDoctor') === 1 &&
			(mensural_hx || sexual_hx || obstetrics_hx || physical_examination)
		) {
			this.props.history.push({
				pathname: `/patient/obs-gynae/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
				state: { patient },
			});
		} else {
			this.setState({ continueModalForNurse: !this.state.continueModalForNurse });
		}
	}

	continueActionForNurse = (action = "") => {
		const { patient, patientUrlData } = this.state;
		const { patient_id, qms_token_id, hospital_id } = patientUrlData;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
		this.setState({ continueModalForNurse: false }, () => {
			if (action === "yes") {
				this.props.history.push({
					pathname: `/patient/history/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
					state: { patient },
				});
				return;
			}

			if (action === "no") {
				this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
				return;
			}
		});

	}

	patientAgeFilterSelect = (e, state) => {
		this.setState({
			[state]: e
		});
	}

	disabledDate(current) {
		return current && current > moment();
	}

	// ################################# For Start Social Hx ###########################################
	// For Social Hx Change
	handleForSocialHxChange = d => {
		let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;
		this.setState({ [name]: value })
		this.setState({
			habit: value,
			habitStatus: '1'
		}, () => {

			if (this.state.habit === "0") {
				this.socialHxFormRef.current.resetFields();
				this.socialHxFormRef.current.setFieldsValue({
					habit: "0",
					time: "0",
					status: "1",
					frequency: "0",
					quantity_unit: "0",
					route: "0"
				})
			}

			if (this.state.habit === "1") {
				this.socialHxFormRef.current.resetFields();
				this.socialHxFormRef.current.setFieldsValue({
					habit: "1",
					time: "0",
					status: "1",
					frequency: "0",
					quantity_unit: "6",
				})
			}

			if (this.state.habit === "2") {
				this.socialHxFormRef.current.resetFields();
				this.socialHxFormRef.current.setFieldsValue({
					habit: "2",
					time: "0",
					status: "1",
					frequency: "0",
					quantity_unit: "7",
				})
			}


			if (this.state.habit === "3") {
				this.socialHxFormRef.current.resetFields();
				this.socialHxFormRef.current.setFieldsValue({
					habit: "3",
					time: "0",
					status: "1",
					frequency: "0",
					quantity_unit: "7",
					route: '0'
				})
			}


		})
		// debugger;
	}



	// For Social Hx Add Submit
	handleSocialAddSubmit = async _ => {
		const { habit, status, since, time, frequency, quantity, quantity_unit, drug_name, route } = this.socialHxFormRef.current.getFieldValue();
		const { fromFieldId } = this.state;

		const PARAMS = {
			type: 'social',
			social_data: [{
				habit,
				status,
				since,
				time,
				frequency,
				quantity,
				quantity_unit,
				drug_name,
				route,
				drug_id: fromFieldId
			}],
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: this.state.patient.qms_token_id,
			patient_id: this.state.patient.patient_id
		}

		//  console.log(PARAMS);
		const socialAddDataPromise = await Axios.post(SOCIAL_HISTORY_UPDATE, qs.stringify(PARAMS))

		if (socialAddDataPromise.data.status) {
			this.handleSocialHxData();
			this.props.initialSetup();
			if (this.state.formModeEdit) {
				this.setState({
					social_message: false,
				});
				this.handleFamilySocialModalPopup(null, 'addSocialModal');
				notification.success({
					message: 'Social Hx Updated Succesfully',
					placement: 'topRight'
				});
			}
			else {
				this.socialHxFormRef.current.resetFields();
				await this.socialHxFormRef.current.setFieldsValue({
					habit: "0",
					time: "0",
					status: "1",
					frequency: "0",
					quantity_unit: "0",
					route: "0",
				});
				await this.setState({
					habit: '0',
					status: '1',
					time: '0',
					frequency: '0',
					quantity_unit: '0',
					route: '0',
				});
				this.setState({
					social_message: true,
				});
				setTimeout(() => {
					this.setState({ social_message: false });
				}, 3000);
			}
			//
		}
	}

	handleSocialHxData = async () => {
		const { patient_id } = this.state.patient;
		const socialPromise = await Axios.get(SOCIALHX_LIST + "/" + patient_id);

		if (socialPromise.data.hasOwnProperty("data") === true) {
			this.setState({
				socialHxData: socialPromise.data.data.record,
				socialHxInactiveData: socialPromise.data.data.inactive_record,
				socialHxFrequencyData: socialPromise.data.data.frequency,
				socialHxHabitData: socialPromise.data.data.habbits,
				socialHxQuantityData: socialPromise.data.data.quantity,
				socialHxRouteData: socialPromise.data.data.route,
				socialHxStatusData: socialPromise.data.data.status,
				socialHxSinceTimeData: socialPromise.data.data.since_time,
				socialHxTableShow: true,
			})
		} else {
			this.setState({ socialHxTableShow: false })
		}
	}

	// ################################# For Start Family Hx ####################################################

	// For Disease Fetch
	fetchDiseaseData = async search => {
		this.setState({ diseaseList: [] ,search_diseases: search });
		const diseaseDataPromise = await Axios.post(DISEASE_SEARCH, qs.stringify({ search }))
		if (diseaseDataPromise.status) {
			let { result } = diseaseDataPromise.data;
			this.setState({ diseaseList: result,
				showAddDiseaseButton: search.length > 2 && result.length === 0
			});
		}
	}

	handleOnDiseaseChange = (disease, props) => {
		const disease_selected_data = {};
		Object.assign(disease_selected_data, props.dataid)
		this.setState({
			disease_selected_data
		});
		this.familyMemberFormRef.current.setFieldsValue({
			disease_name: props.dataid.name,
		});

		document.getElementById("family_member").focus();
		AntSelectLabels();
	}


	// For Family Member Fetch
	fetchFamilyMemberData = async search => {
		this.setState({ FamilyMemberList: [] });
		const FamilyMemberPromise = await Axios.post(RELATIONSHIP_SEARCH, qs.stringify({ search }))
		if (FamilyMemberPromise.status) {
			let { result } = FamilyMemberPromise.data;
			this.setState({ FamilyMemberList: result, FamilyMemberListLoaded: true });
		}
	}

	handleOnRelationshipChange = (relationship, props) => {

		let relationship_selected_data_id = [];
		let relationship_selected_data_name = [];

		//  debugger;  
		props.forEach(element => {
			relationship_selected_data_id.push(element.dataid.id);
			relationship_selected_data_name.push(element.dataid.long);
		});

		this.setState({
			relationship_selected_data_id: relationship_selected_data_id.join(),
			relationship_selected_data_name: relationship_selected_data_name.join(),
		})
		document.getElementById("family_member").blur();
		AntSelectLabels();
	}

	// For Family Hx Add Submit
	handleFamilyHxAddSubmit = async _ => {
		const { formModeEdit, disease_id, disease_name, disease_selected_data, relationship_selected_data_id,
			relationship_selected_data_name, patient ,search_diseases} = this.state;

		const PARAMS = {
			type: 'family',
			family_data: {
				disease_id: formModeEdit ? (parseInt(disease_id) ?parseInt(disease_id) : null) : (parseInt(disease_selected_data.id)? parseInt(disease_selected_data.id): null) || null,
				disease_text: formModeEdit ? disease_name : (disease_selected_data?.name?disease_selected_data?.name :search_diseases) || search_diseases,
				family_member_id: relationship_selected_data_id,
				family_text: relationship_selected_data_name
			},
			reference_type: LS_SERVICE.get('reference_type'),
			reference_id: patient.qms_token_id,
			patient_id: patient.patient_id
		}

		const familyHxAddDataPromise = await Axios.post(FAMILY_STORE, qs.stringify(PARAMS))

		if (familyHxAddDataPromise.data.status) {
			this.setState({
				formModeEdit: false,
				disease_id: '',
				disease_selected_data: {},
			})
			this.handleFamilyHxData();
			this.props.initialSetup();
			if (this.state.formModeEdit) {
				this.setState({
					family_message: false,
				});
				this.handleFamilySocialModalPopup(null, 'addFamilyModal');
				notification.success({
					message: 'Family Hx Updated Succesfully',
					placement: 'topRight'
				});
			}
			else {
				this.setState({
					family_message: true,
				});
				this.setState({
					disease_id: '',
					disease_name: '',
					relationship_selected_data_id: undefined
				});
				setTimeout(() => {
					this.setState({ family_message: false });
				}, 3000);
			}

			this.familyMemberFormRef.current.resetFields();
		}
	}

	handleFamilyHxData = async () => {
		const { patient_id } = this.state.patient;
		const familyHxPromise = await Axios.get(FAMILY_HISTORY_LIST + "/" + patient_id);
		if (familyHxPromise.hasOwnProperty("data") === true) {
			this.setState({
				familyHistoryData: familyHxPromise.data.data ? familyHxPromise.data.data.record : {},
				familyHistoryInactiveData: familyHxPromise.data.data ? familyHxPromise.data.data.inactive_record : {}
			})
		}

	}

	handleForRadioChange = d => {
		let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;
		this.setState({
			[name]: value
		})

		if (this.socialHxFormRef.current) {
			const { since } = this.socialHxFormRef.current.getFieldValue();
			if (value === '1' && (since === '' || since === undefined)) {
				this.socialHxFormRef.current.setFieldsValue({
					time: '0'
				})
				this.setState({
					time: '0'
				})
			}

			if (value === '2' && (since === '' || since === undefined)) {
				this.socialHxFormRef.current.setFieldsValue({
					time: '3'
				})
				this.setState({
					time: '3'
				})
			}
		}

		if (this.state.actionType === 'editSocial') {
			this.setState({ habitStatusEdit: false })
		}
	}

	deleteConfirmation = (event, id, formName) => {
		event.preventDefault();
		const history_id = id;
		const {userName, userId } = this.state;
		// For Family Hx
		if (formName === "Family Hx") {
			Axios.get(FAMILY_HISTORY_DELETE({ history_id, userName, userId }))
				.then(response => {
					//console.log(response);
					this.handleFamilyHxData();
					this.setState({ isDeleteVisible: false });

					notification.success({
						message: response.data.message,
						placement: 'topRight'
					});
					this.props.initialSetup();
				})
		}

		// For Social Hx 
		if (formName === "Social Hx") {
			Axios.get(SOCIAL_HISTORY_DELETE({ history_id, userName, userId }))
				.then(response => {
					//console.log(response);
					this.handleSocialHxData();
					this.setState({ isDeleteVisible: false });
					notification.success({
						message: response.data.message,
						placement: 'topRight'
					});
					this.props.initialSetup();
				})
		}
	}

	handleBloodGroup = (condition, props) => {
		if (props.value != null) {
			this.setState({
				patient_blood_group: props.value
			});
			this.bloodGroupSave(props.value);
		}
	}

	bloodGroupSave = (patient_blood_group) => {
		const { patient } = this.state;
		var blood_group_id = "";
		console.log(patient_blood_group);
		if (Object.keys(this.state.BloodGroupList).length > 0) {
			for (var i = 0; i <= Object.keys(this.state.BloodGroupList).length; i++) {
				if (this.state.BloodGroupList[i] == patient_blood_group) {
					blood_group_id = i;
					break;
				}
			}
		}

		const PARAMS = {
			patient_blood_group: blood_group_id,
			patient_id: patient.patient_id,
		}
		Axios.post(BLOOD_GROUP_UPADTE, qs.stringify(PARAMS))
			.then(res => {
				this.props.getPatientData();
				notification.success({
					message: res.data.message,
					placement: 'topRight'
				});
			})
	}

	showHeaderForNurse(patient) {
		if (this.state.loginRole === 'nurse') {
			if (patient != undefined && patient.patient_id != undefined && patient.patient_id != null) {
				return null;
			}
		} else {
			if (patient != undefined && patient.patient_id != undefined && patient.patient_id != null) {
				return <SubHeader patient={patient} />
			}
		}
	}

	enablePractice = async (e) => {
		e.preventDefault();
		practiceService.init({ initiater: "SOAP Pages", status: "start" });
		const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
			hospital_id: this.state.hospital_id,
			practice_session: 'start'
		});

		let is_practicing;
		if (practice.data && practice.data.is_practicing === 'Y') {
			is_practicing = true;
			LS_SERVICE.set('practicing', {
				status: true,
				hospital_id: this.state.hospital_id
			});
		} else {
			LS_SERVICE.delete('practicing');
			is_practicing = false;
		}

		this.setState(
			{
				isPracticing: is_practicing,
			},
			() => {
				this.handleModalPopup(null, "isPracticingModal");
			}
		);
	};

	onDrugssearch = (search) => {
		this.setState({
			formIsFetchingDrug: true,
			drug_name: search
		})
		let params = { search: search };
		if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
			params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
		} else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
			params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
		} else {
			params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
		}
    params.store_id = LS_SERVICE.get("STORE_ID");
    params.facility_id = LS_SERVICE.get("FACILITY_ID");
    params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    if(!search || search == null || search == ''){
      this.setState({
        searchDrugs: LS_SERVICE.get("favouriteMedicines") || [],
        formIsFetching: false,
        showAddDrugButton: true
      });
    }
    else{
      Axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
        this.setState({
          searchDrugs: success.data.medicine_list,
          formIsFetchingDrug: false,
          showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0) ? false : true,
        })
      }).catch(err => console.log(err));
    }
	}


	onChangeDrugsSelect = async (value, props) => {
		await this.setState({
			drug_id: props.drug_id,
			drug_name: value,
		});
	}

	optionDrugLoad() {
		if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
			return this.state.searchDrugs.map((obj, i) => {

				return (<Option key={i} value={obj.name} drug_id={obj.id} fav={obj.fav} disabled={(this.state.addedDrugsListsId && this.state.addedDrugsListsId.includes(obj.id))} >
					<span className="desc">
						{obj.name}
					</span>
					{/* <span className="float-right text-primary">
				{(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
				  <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
				  : null}
				{this.state.cimsallow ? (
				  <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
					<i className='icon_info'></i>
				  </Button>
				) : null}
			  </span> */}
				</Option>
				)
			})
		} else return null
	}

	cancelledViewPopup(type) {
		this.setState({
		  [type]: true
		});
	}

	nowCurrentDate = (days, units) => {
		let date_type = 'days';
		switch (parseInt(units)) {
		  case 0:
			date_type = 'days';
			break;
		  case 1:
			date_type = 'weeks';
			break;
		  case 2:
			date_type = 'months';
			break;
		  case 3:
			date_type = 'years';
			break;
		}
	
		let date = moment().subtract(days, date_type).format('MMM, YYYY');
		return date;
	  }

	render() {
		const {
			allergies,
			conditions,
			surgical_histories,
			allergy_selected_data,
			conditionList,
			condition,
			allergyList,
			allergy_food,
			allergy_other,
			allergy_selected,
			allergy,
			formModeEdit,
			patient,
			disease,
			diseaseList,
			relationship,
			FamilyMemberList,
			allowEdit,
			BloodGroupList,
			ss_configuration,
			consult_type,
			sourcescreen,
			patientInactiveAllergies
		} = this.state;

		let headerHabitCancelled =
			<tr>
				<th style={{ color: '#3b3f45' }} width="150">Substance</th>
				<th style={{ color: '#3b3f45' }} width="150">Status</th>
				<th style={{ color: '#3b3f45', wordBreak: 'break-word' }} width="150">Since When</th>
				<th style={{ color: '#3b3f45' }} width="160">Frequency</th>
				<th style={{ color: '#3b3f45' }} width="110">Quantity</th>
				<th style={{ color: '#3b3f45' }} width="160">Inactive By</th>
				<th style={{ color: '#3b3f45' }} width="160">Inactive At</th>
			</tr>;

		var child_variable = {
			blood_group: null,
			allergies: null,
			existing_conditions: null,
			surgical_hx: null,
			social_hx: null,
			family_hx: null,
		}
		var child_patient_hx;
		if (ss_configuration) {
			child_patient_hx = ss_configuration.find(o => o?.id === 2).sub_items;

			child_variable.blood_group = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 3 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row'>
								<div className='col-12'>
									<h6 className='text-primary mb-0 pat-sub-h6'>BLOOD GROUP</h6>
								</div>
							</div>
							<div className='row my-2'>
								<div className='col-4 my-1'>
									<div className='card pathistory_card p-2 mb-0'>
										<Form
											id="bloodGroupForm"
											ref={this.bloodGroupRef}
											layout="vertical"
										>
											<div className='row d-flex align-items-center'>
												<div className='col-5'>
													<div className={(this.state.patient_blood_group) ? "antSelectItem hasdata" : "antSelectItem"}>
														<Form.Item
															label="Blood Group"
															name="patient_blood_group"
														>
															<Select
																value={this.state.patient_blood_group}
																select={this.state.patient_blood_group}
																style={{ width: '100%' }}
																onChange={this.handleBloodGroup}
																id="patient_blood_group"
																name="patient_blood_group"
																filterOption={(input, option) => {
																	return (
																		option.children
																			.toString()
																			.toLowerCase()
																			.indexOf(input.toString().toLowerCase()) >= 0
																	);
																}}
															>

																{typeof this.state.BloodGroupList !== 'undefined' && Object.keys(this.state.BloodGroupList).length > 0 ?
																	Object.keys(this.state.BloodGroupList).map(key =>
																		<option value={this.state.BloodGroupList[key]}>{this.state.BloodGroupList[key]}</option>
																	) : null}
															</Select>
														</Form.Item>
													</div>
												</div>
												<div className='col-7'>
													<span className='text-secondary'>Informed by patient</span>
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</>
						:
						null
				}
			</>
			child_variable.allergies = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row align-items-center mt-2'>
								<div className='col-2'>
									<h6 className='text-primary mb-0 pat-sub-h6'>ALLERGIES</h6>
								</div>
								<div className='col-2'>
									<a href="javascript:void(0);" className="text-dark" onClick={e => this.cancelledViewPopup('cancelledAllergy')}  >View Inactive Details</a>
								</div>
								<div className='col-3'>
									<span class="text-dark mr-2" style={{ fontWeight: '500' }}>Severity</span>
									<ul class="severity_list">
										<li><i class="icon-severity-low"></i> Low</li>
										<li><i class="icon-severity-medium"></i> Moderate</li>
										<li><i class="icon-severity-high"></i> High</li>
									</ul>
								</div>
								<div className='col-3 d-flex justify-content-start'>
									<Checkbox
										id="known_allergies"
										name="noallergies"
										checked={this.state.noallergies}
										value={this.state.noallergies}
										disabled={!this.state.allowEdit}
										onChange={(e) => this.handleOnCheckBoxChange(e, "allergies")}
									>
										<span>No Known Allergies</span>
									</Checkbox>
								</div>
								<div className='col-2 pr-5'>
									<div class="float-right">
										{this.state.allowEdit ? (
											<button
												type="button"
												className="float-md-right btn btn-outline-primary text-uppercase px-2"
												disabled={this.state.noallergies}
												onClick={(e) => this.handleModalPopup(e, "allergyModal")}
											>
												+ Allergies
											</button>
										) : null}
									</div>
								</div>
							</div>
							{allergies && allergies.length > 0 ? (
								<div className='row mt-1 mb-2'>
									{allergies.map((all) =>
										<div className='col-4 my-1'>
											<div className='card pathistory_card p-2 mb-0'>
												<div className='row'>
													{(all.intensity != null) ?
														<div className='col-1 align-self-center'>
															<i className={(all.intensity != null) ? `float-right ${all.intensity === 0
																? "icon-severity-low"
																: all.intensity === 1
																	? "icon-severity-medium"
																	: "icon-severity-high"
																}` : ''}
															></i>
														</div>
														: ''}
													<div className={(all.intensity != null) ? "col-8" : "col-9"}>
														<span className='obj_card_text'>{all.allergy_name} {all.reaction ? "(" + all.reaction + ")" : null}</span><br />
														{all.duration ?
															<span className='obj_duration_text'>Duration: {all.duration} {DURATION_TYPE[all.durationType]}</span>
															: null}
													</div>

													{this.state.allowEdit ? (
														<div className='col-3'>
															<span class="float-right">
																<a class="nostyle-link edit-allergy pr-1" onClick={(e) => this.handleModalPopup(e, "allergyModal", all)}><i class="icon-edit"></i></a>
																<a class="nostyle-link remove-list remove-allergy pl-1" onClick={(e) => this.handleModalPopup(e, "deleteModal", all, "allergy")}><i class="icon_delete"></i></a>
															</span>
														</div>
													) : null}
												</div>
											</div>
										</div>
									)}
								</div>
							) : null}
						</>
						:
						null
				}
			</>
			child_variable.existing_conditions = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row align-items-center mt-2'>
								<div className='col-5'>
									<h6 className='text-primary mb-0 pat-sub-h6'>EXISTING CONDITIONS</h6>
								</div>
								<div className='col-2'>
									<a href="javascript:void(0);" className="text-dark nostyle-link" onClick={e => this.cancelledViewPopup('cancelledExistingConditions')} >View Inactive Details</a>
								</div>
								<div className='col-3 d-flex justify-content-start'>
									<Checkbox
										id="known_existing_conditions"
										name="noExistingCondition"
										value={this.state.noExistingCondition}
										checked={this.state.noExistingCondition}
										disabled={!this.state.allowEdit}
										onChange={(e) => this.handleOnCheckBoxChange(e, "conditions")}
									>
										<span>No Known Existing Condition</span>
									</Checkbox>
								</div>
								<div className='col-2 pr-5'>
									<div class="float-right">
										{this.state.allowEdit ? (
											<button
												type="button"
												className="float-md-right btn btn-outline-primary text-uppercase px-2"
												disabled={this.state.noExistingCondition}
												onClick={(e) => this.handleModalPopup(e, "conditionModal")}
											>
												+ Condition
											</button>
										) : null}
									</div>
								</div>
							</div>
							{conditions && conditions.length > 0 ? (
								<div className='row my-2'>
									{conditions.map((c, i) => (
										<div className='col-4 my-1'>
											<div className='card pathistory_card p-2 mb-0'>
												<div className='row'>
													<div className='col-9'>
														<span className='obj_card_text'>{c.condition_description}</span>
													</div>
													<div className='col-3'>
														<span class="float-right">
															<a class="nostyle-link edit-condition pr-1" onClick={(e) => this.handleModalPopup(e, "conditionModal", c)}><i class="icon-edit"></i></a>
															<a class="nostyle-link remove-list remove-condition pl-1" onClick={(e) => this.handleModalPopup(e, "deleteModal", c, "condition")}><i class="icon_delete"></i></a>
														</span>
													</div>
												</div>
												<div className='row'>
													{c.duration ?
														<div className='col-12'>
															<span className='obj_duration_text'>Duration: {`${c.duration} ${DURATION_TYPE[c.durationType]}`}</span>
														</div>
														: null}
												</div>
												{c.onMedication == 1 ? 
													<div className='row'>
														<div className='col-12'>
															<span className='obj_card_text'>{c.medicineName}</span>
														</div>
													</div>
													:
													null
												}
											</div>
										</div>
									))}
								</div>
							) : null}
						</>
						:
						null
				}
			</>
			child_variable.surgical_hx = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row align-items-center mt-2'>
								<div className='col-5'>
									<h6 className='text-primary mb-0 pat-sub-h6'>SURGICAL HX</h6>
								</div>
								<div className='col-2'>
									<a href="javascript:void(0);" className="text-dark" onClick={e => this.cancelledViewPopup('cancelledSurgicalHistory')} >View Inactive Details</a>
								</div>
								<div className='col-3 d-flex justify-content-start'>
									<Checkbox
										id="known_surgicalHx"
										name="noSurgicalHx"
										checked={this.state.noSurgicalHx}
										disabled={!this.state.allowEdit}
										value={this.state.noSurgicalHx}
										onChange={(e) => this.handleOnCheckBoxChange(e, "surgical_histories")}
									>
										<span>No Known Surgical Hx</span>
									</Checkbox>
								</div>
								<div className='col-2 pr-5'>
									<div class="float-right">
										{this.state.allowEdit ? (
											<button
												type="button"
												className="float-md-right btn btn-outline-primary text-uppercase px-2"
												disabled={this.state.noSurgicalHx}
												onClick={(e) => this.handleModalPopup(e, "surgicalModal")}
											>
												+ Surgical Hx
											</button>
										) : null}
									</div>
								</div>
							</div>
							{surgical_histories && surgical_histories.length > 0 ? (
								<div className='row my-2'>
									{surgical_histories.length > 0
										? surgical_histories.map((sh, idx) => (
											<div className='col-4 my-1'>
												<div className='card pathistory_card p-2 mb-0'>
													<div className='row'>
														<div className='col-9'>
															<span className='obj_card_text'>{sh.reason} {sh.remarks ? "(" + sh.remarks + ")" : null}</span>
														</div>
														<div className='col-3'>
															<span class="float-right">
																<a class="nostyle-link edit-history pr-1" onClick={(e) => this.handleModalPopup(e, "surgicalModal", sh)}><i class="icon-edit"></i></a>
																<a class="nostyle-link remove-list remove-history pl-1" onClick={(e) => this.handleModalPopup(e, "deleteModal", sh, "surgical")}><i class="icon_delete"></i></a>
															</span>
														</div>
													</div>
												</div>
											</div>
										))
										: null}
								</div>
							) : null}
						</>
						:
						null
				}
			</>
			child_variable.social_hx = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row align-items-center mt-2'>
								<div className='col-5'>
									<h6 className='text-primary mb-0 pat-sub-h6'>SOCIAL HX</h6>
								</div>
								<div className='col-2'>
									<a href="javascript:void(0);" className="text-dark" onClick={e => this.cancelledViewPopup('cancelledSocialHistory')} >View Inactive Details</a>
								</div>
								<div className='col-3 d-flex justify-content-start'>
									<Checkbox
										id="known_socialHx"
										name="noSocialHx"
										checked={this.state.noSocialHx}
										disabled={!this.state.allowEdit}
										value={this.state.noSocialHx}
										onChange={(e) => this.handleOnCheckBoxChange(e, "socialHxData")}
									>
										<span>No Known Social Hx</span>
									</Checkbox>
								</div>
								<div className='col-2 pr-5'>
									<div class="float-right">
										{this.state.allowEdit ? (
											<button
												type="button"
												className="float-md-right btn btn-outline-primary text-uppercase px-2"
												disabled={this.state.noSocialHx}
												onClick={e => this.handleFamilySocialModalPopup(e, 'addSocialModal')}
											>
												+ SOCIAL HX
											</button>
										) : null}
									</div>
								</div>
							</div>
							<div className='row my-2'>
								{this.state.socialHxTableShow === true ? (
									<>
										{

											typeof this.state.socialHxData !== 'undefined' && Object.keys(this.state.socialHxData).length > 0 ?
												this.state.socialHxData.map((socialObj, index) =>
												(
													<div className='col-4 my-1'>
														<div className='card pathistory_card p-2 mb-0'>
															<div className='row'>
																<div className='col-9'>
																	<span className='obj_card_text'>{this.state.socialHxHabitData[socialObj.details] || "NA"}</span>
																</div>
																<div className='col-3'>
																	<span class="float-right">
																		{allowEdit && patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
																			<>
																				<a class="nostyle-link edit-socail pr-1" onClick={e => this.handleFamilySocialModalPopup(e, 'addSocialModal', socialObj, 'editSocial')}><i class="icon-edit"></i></a>
																				<a class="nostyle-link remove-list remove-social pl-1" onClick={(e) => this.removeItem(e, socialObj.id, 'Social Hx')}><i class="icon_delete"></i></a>
																			</> : null}
																	</span>
																</div>
															</div>
															<div className='row'>
																<div className='col-12'>
																	<span className='obj_duration_text'>Status: {this.state.socialHxStatusData[socialObj.options] || "NA"}</span>
																</div>
															</div>
															<div className='row'>
																{this.state.socialHxSinceTimeData[socialObj.time] ?
																	<>
																	<div className='col-12'>
																		<span className='obj_duration_text'>Duration: {socialObj.since} {this.state.socialHxSinceTimeData[socialObj.time] || "NA"}</span>
																	</div>
																	<div className='col-12'>
																		<span className='obj_duration_text'>Frequency: {this.state.socialHxFrequencyData[socialObj.frequency]} ({socialObj.quantity} {this.state.socialHxQuantityData[socialObj.quantityUnit] || "NA"})</span>
																	</div>
																	{socialObj.drugName != "" && socialObj.drugName != null ?
																		<div className='col-12'>
																			<span className='obj_duration_text'>Medicine: {socialObj.drugName}</span>
																		</div>
																		:
																		null
																	}
																	</>
																	: null}
															</div>
														</div>
													</div>
												)) : null}
									</>
								) : ''
									// 'Info not available.'
								}
							</div>
						</>
						:
						null
				}
			</>
			child_variable.family_hx = <>
				{
					sourcescreen != 'singlescreen'
						||
						(sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
						?
						<>
							<div className='row d-flex align-items-center mt-2'>
								<div className='col-5'>
									<h6 className='text-primary mb-0 pat-sub-h6'>FAMILY HX</h6>
								</div>
								<div className='col-2'>
									<a href="javascript:void(0);" className="text-dark" onClick={e => this.cancelledViewPopup('cancelledFamilyHistory')}  >View Inactive Details</a>
								</div>
								<div className='col-3 d-flex justify-content-start'>
									<Checkbox
										id="known_familyHx"
										name="noFamilyHx"
										checked={this.state.noFamilyHx}
										disabled={!this.state.allowEdit}
										value={this.state.noFamilyHx}
										onChange={(e) => this.handleOnCheckBoxChange(e, "familyHistoryData")}
									>
										<span>No Known Family Hx</span>
									</Checkbox>
								</div>
								<div className='col-2 pr-5'>
									<div class="float-right">
										{this.state.allowEdit ? (
											<button
												type="button"
												className="float-md-right btn btn-outline-primary text-uppercase px-2"
												disabled={this.state.noFamilyHx}
												onClick={e => this.handleFamilySocialModalPopup(e, 'addFamilyModal')}
											>
												+ FAMILY HX
											</button>
										) : null}
									</div>
								</div>
							</div>
							<div className='row my-2'>
								{typeof this.state.familyHistoryData !== 'undefined' && Object.keys(this.state.familyHistoryData).length > 0 ?
									this.state.familyHistoryData.map(familyObj =>
										<div className='col-4 my-1'>
											<div className='card pathistory_card p-2 mb-0'>
												<div className='row'>
													<div className='col-9'>
														<span className='obj_card_text'>{familyObj.diseaseName} </span>
													</div>
													<div className='col-3'>
														<span class="float-right">
															{patient.qms_token_id !== null && patient.qms_token_id !== undefined && allowEdit ?
																<>
																	<a class="nostyle-link edit-family pr-1" onClick={e => this.handleFamilySocialModalPopup(e, 'addFamilyModal', familyObj, 'editFamily')}><i class="icon-edit"></i></a>
																	<a class="nostyle-link remove-list remove-family pl-1" onClick={(e) => this.removeItem(e, familyObj.id, 'Family Hx')}><i class="icon_delete"></i></a>
																</> : null}
														</span>
													</div>
												</div>
												<div className='row'>
													<div className='col-12'>
														<span className='obj_duration_text'>Member: {(familyObj.relationshipName).replace(/,/g, ", ")}</span>
													</div>
												</div>
											</div>
										</div>
									) : null}
							</div>
						</>
						:
						null
				}
			</>
		}

		return (
			<div className="card-body pathistory_body p-0">
				<div className='container-fluid emrfrm pathistory_container mt-3'>
					{
						child_patient_hx && child_patient_hx.length > 0
							?
							(
								child_patient_hx.map((ssc, i) => {
									return (child_variable[ssc.name])
								})
							)
							:
							null
					}

					{/* || SURGICAL MODAL */}
					<Modal
						title={`${formModeEdit ? "Edit " : "Add "} Surgical History`}
						visible={this.state.surgicalModal}
						onCancel={(e) => this.handleModalPopup(e, "surgicalModal")}
						style={{ top: 40 }}
						bodyStyle={{ paddingBottom: "10px" }}
						width="550px"
						className="emrfrm"
						footer={[
							<div className="text-center">
								<Button type="button" className="btn btn-outline-secondary  mr-3" onClick={(e) => this.handleReset(e, 'surgicalHistory')}>CLEAR</Button>
								<Button form="surgeryForm" type="primary" className="px-4" htmlType="submit" disabled={this.state.spin_loading}>
									SAVE{this.state.spin_loading ? LOADER : ""}
								</Button>
							</div>,
							<div className="text-center mt-3">
								{this.state.surgical_message ?
									<span style={{ color: '#548f3a' }}>Surgical Hx Added Successfully</span>
									:
									null
								}
							</div>,
						]}
					>
						<div className="row">
							<div className="col-12">
								<Form
									id="surgeryForm"
									ref={this.surgeryFormRef}
									validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
									onFinish={this.handleSurgeryAddSubmit}
									layout="vertical"
								>
									<div className="antInputItem">
										<Form.Item
											name="history"
											label="Surgery Name"
											rules={[
												{ required: true, message: "Please enter surgery" },
												// {
												// 	pattern: /^(?=[a-zA-Z\d\s]{0,48}$)([a-zA-Z01-9]+ ?)*$/,
												// 	message: "Maximum 50 characters.",
												// },
											]}
										>
											{/* <Input autoFocus={true} autoComplete="off" /> */}
											<TextArea autoFocus={true} autoComplete="off" rows={3} />
										</Form.Item>
									</div>
									<div className="antInputItem">
										<Form.Item
											name="remarks"
											label="Remarks"
										>
											<TextArea autoComplete="off" name="remarks" onChange={(e) => this.handleOnChange(e, 'surgicalHx')} rows={3} />
										</Form.Item>
									</div>
									{/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
								</Form>
							</div>
						</div>
					</Modal>

					{/* Modal For Family Hx */}
					<Modal
						title={this.state.actionText + " Family History"}
						visible={this.state.addFamilyModal}
						onCancel={(e) => this.handleFamilySocialModalPopup(e, 'addFamilyModal')}
						bodyStyle={{ paddingBottom: '15px' }}
						className="emrfrm"
						footer={false}
					>

						<Form
							ref={this.familyMemberFormRef}
							validateMessages={HANDLEVALIDANTMESSAGES}
							onFinish={this.handleFamilyHxAddSubmit}
							layout="vertical">

							<div className="antSelectItem">
								<div className="form-group add_buttons font-col">
									{(this.state.showAddDiseaseButton) ?
										<a className={"btn btn-outline-primary"} onClick={() => this.handleAddButton("showAddDiseaseButton")}>ADD</a>
										:
										null
									}
									<Form.Item name="disease_name" label="Disease name"
										rules={[{
											required: true,
											message: 'Please choose disease',
										}]}
									>
										<AutoComplete
											showSearch
											value={this.state.disease_selected_data.name}
											autoFocus
											notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
											loading={this.state.formIsFetching}
											filterOption={false}
											onSearch={this.fetchDiseaseData}
											onSelect={this.handleOnDiseaseChange}
											showArrow={!this.state.showAddDiseaseButton}
											style={{ width: '100%' }}
											disabled={this.state.formModeEdit}
										>
											{diseaseList !== undefined && diseaseList.length > 0 ? diseaseList.map(d => (
												<Option dataid={d} key={d.id}>{d.name}</Option>
											)) : null}
										</AutoComplete>
									</Form.Item>
								</div>
							</div>

							<div className="antSelectItem">
								<Form.Item name="family_member" label="Family Member"
									rules={[{
										required: true,
										message: 'Please choose family member',
									}]}
								>
									{/* mode="multiple" */}
									<Select
										name="family_member"
										showSearch
										mode="multiple"
										value={relationship}
										notFoundContent={null}
										filterOption={true}
										// onSearch={this.fetchFamilyMemberData}
										onChange={this.handleOnRelationshipChange}
										defaultValue={this.state.relationship_selected_data_id !== undefined ? this.state.relationship_selected_data_id.split(',') : []}
										style={{ width: '100%' }}
										optionFilterProp="children"
									>
										{this.state.FamilyMemberListLoaded && FamilyMemberList !== undefined && FamilyMemberList.length > 0 ? FamilyMemberList.map(d => (
											<Option dataid={d} key={d.id}>{d.long}</Option>
										)) : null}
									</Select>
								</Form.Item>
							</div>

							<Form.Item className="text-center mt-4">
								<Button type="button" className="btn btn-outline-secondary  mr-3" onClick={(e) => this.handleReset(e, 'familyHistory')}>CLEAR</Button>
								<Button type="primary" htmlType="submit" className="px-4">SAVE </Button>
							</Form.Item>

							<div className="text-center mt-3">
								{this.state.family_message ?
									<span style={{ color: '#548f3a' }}>Family Hx Added Successfully</span>
									:
									null
								}
							</div>
						</Form>
					</Modal>

					{/* Modal For Social  History */}
					<Modal
						title={this.state.actionText + " Social History"}
						visible={this.state.addSocialModal}
						onCancel={(e) => this.handleFamilySocialModalPopup(e, 'addSocialModal')}
						width="700px"
						bodyStyle={{ paddingBottom: '15px' }}
						className="emrfrm"
						footer={false}
					// closable={false}
					>

						<Form
							ref={this.socialHxFormRef}
							validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
							onFinish={this.handleSocialAddSubmit}
							layout="vertical">

							<Form.Item
								name="habit" onChange={this.handleForSocialHxChange}>
								<Radio.Group name="habit">
									{!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '0') ? (
										<Radio value="0" className="mr-4">Smoking</Radio>
									) : null}

									{!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '1') ? (
										<Radio value="1" className="mr-4">Tobacco</Radio>
									) : null}

									{!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '2') ? (
										<Radio value="2" className="mr-4">Alcohol</Radio>
									) : null}

									{!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '3') ? (
										<Radio value="3">Drugs</Radio>
									) : null}
								</Radio.Group>
							</Form.Item>

							{/* For smoking Tab */}
							{this.state.habit === '0' ? (
								<>
									<div className="ant-row">
										<div className="col-5 pl-0">
											<Form.Item name="status" label={<strong>Do you smoke?</strong>} onChange={this.handleForRadioChange}>
												<Radio.Group name="habitStatus" value={this.state.status}>
													<Radio value="1" className="mr-4">Yes</Radio>
													<Radio value="0" className="mr-4">No</Radio>
													<Radio value="2">Quit</Radio>
												</Radio.Group>
											</Form.Item>

										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been smoking?" : "When did you quit smoking?"}</strong></label>
													<div className="col pr-0">
														<Form.Item
															name="since"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: this.handleMaxLimit(this.state.time),
																// message: "Duration cannot be greater than patient's age",
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}
														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="time">
															<Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
																<Option value="0">Days</Option>
																<Option value="1">Weeks</Option>
																<Option value="2">Months</Option>
																<Option value="3">Years</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


									<div className="ant-row">
										<div className="col-5 pl-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<Form.Item name="frequency" label={<strong>Frequency</strong>}>
													<Radio.Group defaultValue={this.state.frequency}>
														<Radio value="0" className="mr-4">Daily</Radio>
														<Radio value="1" className="mr-4">Occasionally</Radio>
													</Radio.Group>
												</Form.Item>
											) : ""}
										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Quantity" : "What quantity were you smoking?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="quantity" className="form-label-group input-group"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: 99,
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}
														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="quantity_unit">
															<Select name="quantity_unit" style={{ width: 120 }}
																defaultValue={this.state.quantity_unit}
															>
																<Option value="0">Cigarettes</Option>
																<Option value="1">Cigars</Option>
																<Option value="2">Bidis</Option>
																<Option value="3">Hookahs</Option>
																<Option value="4">Pipes</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


								</>
							) : null}

							{/* For Tobacco Tab */}
							{this.state.habit === '1' ? (
								<>
									<div className="ant-row">
										<div className="col-5 pl-0">
											<Form.Item name="status" label={<strong>Do you take tobacco?</strong>} onChange={this.handleForRadioChange}>
												<Radio.Group name="habitStatus" defaultValue={this.state.status}>
													<Radio value="1" className="mr-4">Yes</Radio>
													<Radio value="0" className="mr-4">No</Radio>
													<Radio value="2">Quit</Radio>
												</Radio.Group>
											</Form.Item>

										</div>
										<div className="col-7 pr-0">

											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been taking tobacco?" : "When did you quit tobacco?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="since" className="onlynumbers"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: this.handleMaxLimit(this.state.time),
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}

														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="time">
															<Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
																<Option value="0">Days</Option>
																<Option value="1">Weeks</Option>
																<Option value="2">Months</Option>
																<Option value="3">Years</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


									<div className="ant-row">
										<div className="col-5 pl-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<Form.Item name="frequency" label={<strong>Frequency</strong>}>
													<Radio.Group defaultValue={this.state.frequency}>
														<Radio value="0" className="mr-4">Daily</Radio>
														<Radio value="1" className="mr-4">Occasionally</Radio>
													</Radio.Group>
												</Form.Item>
											) : ""}
										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "What amount of tobacco are you taking?" : "What amount of tobacco were you taking?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="quantity" className="form-label-group input-group"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: 99,
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}
														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="quantity_unit">
															<Select name="quantity_unit" style={{ width: 120 }}
																defaultValue={this.state.quantity_unit}
															>
																<Option value="5">mg</Option>
																<Option value="6">gm</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


								</>
							) : null}

							{/* For Alcohol Tab */}
							{this.state.habit === '2' ? (
								<>
									<div className="ant-row">
										<div className="col-5 pl-0">
											<Form.Item name="status" label={<strong>Do you consume alcohol?</strong>} onChange={this.handleForRadioChange}>
												<Radio.Group name="habitStatus" defaultValue={this.state.status}>
													<Radio value="1" className="mr-4">Yes</Radio>
													<Radio value="0" className="mr-4">No</Radio>
													<Radio value="2">Quit</Radio>
												</Radio.Group>
											</Form.Item>

										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been consuming alcohol?" : "When did you quit alcohol?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="since" className="onlynumbers"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: this.handleMaxLimit(this.state.time),
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}

														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="time">
															<Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
																<Option value="0">Days</Option>
																<Option value="1">Weeks</Option>
																<Option value="2">Months</Option>
																<Option value="3">Years</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


									<div className="ant-row">
										<div className="col-5 pl-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<Form.Item name="frequency" label={<strong>Frequency</strong>}>
													<Radio.Group defaultValue={this.state.frequency}>
														<Radio value="0" className="mr-4">Daily</Radio>
														<Radio value="1" className="mr-4">Occasionally</Radio>
													</Radio.Group>
												</Form.Item>
											) : ""}
										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "What amount of alcohol are you consuming?" : "What amount of alcohol were you consuming?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="quantity" className="form-label-group input-group"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: 999,
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}
														>
															<Input className="onlynumbers" maxLength="3" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="quantity_unit">
															<Select name="quantity_unit" style={{ width: 120 }} defaultValue={this.state.quantity_unit}
															>
																<Option value="7">ml</Option>
																<Option value="8">liter</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


								</>
							) : null}

							{/* For Drugs Tab */}
							{this.state.habit === '3' ? (
								<>
									<div className="ant-row">
										<div className="col-5 pl-0">
											<Form.Item name="status" label={<strong>Have you ever used recreational drugs?</strong>} onChange={this.handleForRadioChange}>
												<Radio.Group name="habitStatus" defaultValue={this.state.status}>
													<Radio value="1" className="mr-4">Yes</Radio>
													<Radio value="0" className="mr-4">No</Radio>
													<Radio value="2">Quit</Radio>
												</Radio.Group>
											</Form.Item>

										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when did you start using recreational drugs?" : "When did you quit?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="since" className="onlynumbers"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: this.handleMaxLimit(this.state.time),
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}

														>
															<Input maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="time">
															<Select style={{ width: 120 }} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
																<Option value="0">Days</Option>
																<Option value="1">Weeks</Option>
																<Option value="2">Months</Option>
																<Option value="3">Years</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>


									{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
										<>
											<div className="ant-row">
												<div className="col-5 pl-0">
													<Form.Item name="drug_name" label={<strong>Name of drug</strong>} className="form-label-group input-group"
														rules={[{
															required: true,
															message: 'Please enter drug',
														}]}

													>
														<Input />
													</Form.Item>
												</div>
												<div className="col-7 pr-0">
													<Form.Item name="route" label={<strong>Route</strong>}>
														<Radio.Group defaultValue={this.state.route} className="mt-2">
															<Radio value="0" className="mr-4">Oral</Radio>
															<Radio value="1" className="mr-4">IV</Radio>
															<Radio value="2">Others</Radio>
														</Radio.Group>
													</Form.Item>
												</div>
											</div>


										</>
									) : ''}

									<div className="ant-row">
										<div className="col-5 pl-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<Form.Item name="frequency" label={<strong>Frequency</strong>}>
													<Radio.Group defaultValue={this.state.frequency}>
														<Radio value="0" className="mr-4">Daily</Radio>
														<Radio value="1" className="mr-4">Occasionally</Radio>
													</Radio.Group>
												</Form.Item>
											) : ""}
										</div>
										<div className="col-7 pr-0">
											{!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
												<div className="ant-row ant-input-select">
													<label className="col-12"><strong>{this.state.habitStatus === "1" ? "Quantity" : "What quantity of drug were you taking?"}</strong></label>
													<div className="col pr-0">
														<Form.Item name="quantity" className="form-label-group input-group"
															rules={[{
																required: true,
																type: 'number',
																min: 0,
																max: 99,
																transform: function (value) {
																	return value === "" || value === undefined ? value : +value
																}
															}]}
														>
															<Input className="onlynumbers" maxLength="2" />
														</Form.Item>
													</div>
													<div className="col-auto pl-0">
														<Form.Item name="quantity_unit">
															<Select name="quantity_unit" style={{ width: 120 }} defaultValue={this.state.quantity_unit}>
																<Option value="5">mg</Option>
																<Option value="6">gm</Option>
																<Option value="7">ml</Option>
																<Option value="8">litre</Option>
															</Select>
														</Form.Item>

													</div>
												</div>
											) : ''}

										</div>
									</div>
								</>
							) : null}


							<Form.Item className="text-center mt-2">
								<Button type="button" className="btn btn-outline-secondary  mr-3" onClick={(e) => this.handleReset(e, 'socialHistory')}>CLEAR</Button>
								<Button type="primary" htmlType="submit" className="px-4">SAVE </Button>
							</Form.Item>
						</Form>

						<div className="text-center mt-1">
							{this.state.social_message ?
								<span style={{ color: '#548f3a' }}>Social Hx Added Successfully</span>
								:
								null
							}
						</div>

					</Modal>

					{/* || CONDITION MODAL */}
					<Modal
						destroyOnClose={true}
						title={`${formModeEdit ? "Edit " : "Add "} Condition`}
						visible={this.state.conditionModal}
						onCancel={(e) => this.handleModalPopup(e, "conditionModal")}
						style={{ top: 40 }}
						bodyStyle={{ paddingBottom: "10px" }}
						className="emrfrm"
						width="530px"
						footer={[
							<div className="text-center">
								<Button type="button" className="btn btn-outline-secondary  mr-3" onClick={(e) => this.handleReset(e, 'conditions')}>CLEAR</Button>
								<Button form="conditionForm" type="primary" htmlType="submit" disabled={this.state.spin_loading}>
									SAVE{this.state.spin_loading ? LOADER : ""}
								</Button>
							</div>,
							<div className="text-center mt-3">
								{this.state.condition_message ?
									<span style={{ color: '#548f3a' }}>Condition Added Successfully</span>
									:
									null
								}
							</div>,
						]}
					>
						<div className="row">
							<div className="col-12">
								<Form
									id="conditionForm"
									ref={this.conditionFormRef}
									validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
									onFinish={this.handleConditionAddSubmit}
									layout="vertical"
								>
									<div className="antSelectItem mt-2">
										<div className="form-group add_buttons font-col">
										{(this.state.showAddConditionButton) ?
														<a className={"btn btn-outline-primary"} onClick={() => this.handleAddButton("showAddConditionButton")}>ADD</a>
														:
														null
													}
											<Form.Item name="condition_name" label="Condition" rules={[{ required: true }]}>
												{/* {(this.state.showAddConditionButton) ?
													<button className={"btn btn-outline-primary"} disabled={this.state.disableAddConditionButton} onClick={() => this.handleAddCondition()}>ADD</button>
													:
													null
												} */}
												<AutoComplete
													id="condition_name"
													name="condition_name"
													showSearch
													placeholder=" "
													showArrow={!this.state.showAddConditionButton}
													value={condition}
													notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
													loading={this.state.formIsFetching}
													filterOption={false}
													onSearch={this.fetchConditionData}
													onSelect={this.handleOnConditionChange}
													style={{ width: "100%" }}
													disabled={this.state.formModeEdit}
												>
													{conditionList !== undefined && conditionList.length > 0
														? conditionList.map((d) => (
															<Option key={d.id} dataid={d}>
																{d.name}
															</Option>
														))
														: null}
												</AutoComplete>
											</Form.Item>
										</div>
									</div>

									<div className="antInputItem">
										<Form.Item
											name="condition_duration"
											label="Duration"
											// className="onlynumbers"
											rules={[
												{
													// required: true,
													type: "number",
													min: 0,
													max: this.handleMaxLimit(this.state.condition_duration_type),
													transform: function (value) {
														return value === "" || value === undefined ? value : +parseFloat(value);
													}
												},
											]}
										>
											<Input maxLength="5" autoComplete="off" name="condition_duration" onChange={this.handleOnChange} onKeyPress={this.onKeyPress} />
										</Form.Item>
									</div>
									<Form.Item
										name="condition_duration_type"
										className="mt-3"
										rules={[
											{
												required: this.state.condition_duration_type_required,
												message: "Please choose one!",
											},
										]}
									>
										<Radio.Group
											onChange={(e) => {
												this.radioChange(e, "condition_duration_type");
												this.conditionFormRef.current.validateFields();
											}}
										>
											<Radio value="0">Days ago</Radio>
											<Radio value="1">Weeks ago</Radio>
											<Radio value="2">Months ago</Radio>
											<Radio value="3">Years ago</Radio>
										</Radio.Group>
									</Form.Item>
									<Form.Item
										name="medication"
										label={<strong>On Medication?</strong>}
										className="mt-3"
										onChange={this.handleAntFormOnChange}
									// rules={[
									//   {
									//     required: true,
									//     message: "Please choose one!",
									//   },
									// ]}
									>
										<Radio.Group name="medication">
											<Radio value="1">Yes</Radio>
											<Radio value="0">No</Radio>
										</Radio.Group>
									</Form.Item>
									<span className="d-none">{this.state.medication}</span>
									{this.state.medication !== undefined && this.state.medication !== "" && this.state.medication !== "0" ? (
										<div className="antSelectItem mt-2">
											<div className="form-group add_buttons font-col">
												{/* {(this.state.showAddDrugButton) ?
													null
													:
													<a className={"btn btn-outline-primary"} disabled={this.state.showAddDrugButton} onClick={() => this.handleAddDrug()}>ADD</a>
												} */}
												<Form.Item name='medication_name' label='Medication Name' rules={[{
													required: true,
													message: 'please choose medicine name'
												}]}>
													<AutoComplete
														id='medication_name'
														name='medication_name'
														style={{ width: 100 + '%' }}
														showSearch={true}
														// showArrow={this.state.showAddDrugButton}
														notFoundContent={this.state.formIsFetchingDrug ? LOADER_BLACK : null}
														// loading={this.state.formIsFetchingDrug}
														onSelect={this.onChangeDrugsSelect}
														// onSearch={this.onDrugssearch}
														className="select-drugs"
														value={this.state.drug_name}
														filterOption={false}
														dropdownClassName="custom_drug_select"
														autoFocus={this.state.drug_name != null && this.state.drug_name != '' ? false : true}
														showAction={this.state.drug_name != null && this.state.drug_name != '' ? [] : ['focus', 'click']}
														//disabled={this.state.formModeEdit}
													>
														{/* {this.optionDrugLoad()} */}
													</AutoComplete>
												</Form.Item>
											</div>
										</div>
									) : null}
									{/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
								</Form>
							</div>
						</div>
					</Modal>

					{/* || DELETE MODAL */}
					<Modal
						visible={this.state.deleteModal}
						onCancel={(e) => this.handleModalPopup(e, "deleteModal")}
						style={{ top: 40 }}
						footer={[
							<div className="text-center">
								<button className="btn btn-outline-secondary px-5  mr-2 cancel-delete-history" onClick={(e) => this.handleModalPopup(e, "deleteModal")}>
									CANCEL
								</button>
								<button
									className="btn btn-primary px-5 submit-delete-history"
									disabled={this.state.spin_loading}
									onClick={(e) => this.handleDeletePopSubmit(e)}
								>
									DELETE{this.state.spin_loading ? LOADER : ""}
								</button>
							</div>,
						]}
					>
						<div className="row">
							<div className="col-12 my-3 text-center confirm-message">
								<h6>Are you sure you want to delete {this.state.delete_text}?</h6>
							</div>
						</div>
					</Modal>

					{/* || ALLERGY ALREADY EXIST MODAL */}
					<Modal
						visible={this.state.allergyAlreadyExistModal}
						onCancel={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal")}
						style={{ top: 40 }}
						destroyOnClose={true}
						footer={[
							<div className="text-center">
								<button
									className="btn btn-outline-secondary px-5 cancel-delete-history"
									onClick={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal")}
								>
									NO
								</button>
								<button
									className="btn btn-primary px-5 submit-delete-history"
									disabled={this.state.spin_loading}
									onClick={(e) => this.handleModalPopup(e, "allergyAlreadyExistModal", allergy_selected_data)}
								>
									YES{this.state.spin_loading ? LOADER : ""}
								</button>
							</div>,
						]}
					>
						<div className="row">
							<div className="col-12 my-3 text-center confirm-message">
								<h6>Allergy to {this.state.allergyAlreadyExistName} already added. Would you like to replace?</h6>
							</div>
						</div>
					</Modal>

					{/* NO DATA ADDED WARNING MODAL */}
					<Modal
						visible={this.state.noDataModal}
						onCancel={(e) => this.handleModalPopup(e, "noDataModal")}
						style={{ top: 40 }}
						footer={[
							<div className="text-center">
								<button className="btn btn-outline-secondary px-5 cancel-delete-history mr-2" onClick={(e) => this.handleModalPopup(e, "noDataModal")}>
									NO
								</button>
								<button
									className="btn btn-primary px-5 submit-delete-history"
									disabled={this.state.spin_loading}
									onClick={(e) => this.handleObjectiveContinue()}
								>
									YES{this.state.spin_loading ? LOADER : ""}
								</button>
							</div>,
						]}
					>
						<div className="row">
							<div className="col-12 my-3 text-center">
								<h6>{this.state.noDataModalText}</h6>
							</div>
						</div>
					</Modal>

					{/* Modal For Delete Confirmation  */}
					<Modal
						title=""
						visible={this.state.isDeleteVisible}
						onCancel={() => this.deleteModalCancel()}
						// width= "550px"
						closable={false}
						footer={false}
					>
						<form className="emrfrm">
							<div className="row mb-2">
								<div className="col-12 my-3 text-center"><h6>{this.state.removeItemMessage}</h6></div>
								<div className="col-md-12 text-center">
									<button type="button" className="btn btn-outline-secondary px-5 mr-1 mb-3 mb-sm-0" onClick={this.deleteModalCancel}>CANCEL</button>
									<button type="button" id="hx-modal-modal-btn" className="btn btn-primary px-5 ml-1" onClick={(e) => this.deleteConfirmation(e, this.state.removeItemId, this.state.removeFormName)}>DELETE</button>
								</div>
							</div>

						</form>
					</Modal>

					{/** || isPracticing Modal */}
					<Modal title={false} closable={false} visible={this.state.isPracticingModal} footer={false}>
						<div className="row">
							<div className="col-12 my-3 text-center">
								<h6>Do you want to Start Practice?</h6>
							</div>
						</div>
						<div className="modal-footer justify-content-center">
							<div className="btn btn-outline-secondary px-5" onClick={(e) => this.setState({ isPracticingModal: false })}>
								No
							</div>
							<div className="btn btn-primary px-5" onClick={this.enablePractice}>
								Yes
							</div>
						</div>
					</Modal>

					{/** || Continue Modal for nurse */}
					<Modal title={false} closable={false} visible={this.state.continueModalForNurse} footer={false}>
						<div className="row">
							<div className="col-12 my-3 text-center">
								<h6>Do you want to add history for this patient?</h6>
							</div>
						</div>
						<div className="modal-footer justify-content-center">
							<div className="btn btn-outline-secondary px-5" onClick={(e) => this.continueActionForNurse("no")}>
								No
							</div>
							<div className="btn btn-primary px-5" onClick={(e) => this.continueActionForNurse("yes")}>
								Yes
							</div>
						</div>
					</Modal>

					{/* || ALLERGIES MODAL */}
					{this.state.allergyModal ? (
						<Modal
							title={`${formModeEdit ? "Edit " : "Add "} Allergies`}
							visible={this.state.allergyModal}
							onCancel={(e) => this.handleModalPopup(e, "allergyModal") }
							
							style={{ top: 40 }}
							width="530px"
							bodyStyle={{ paddingBottom: "10px" }}
							className="emrfrm"
							footer={[
								<div className="text-center">
									<Button type="button" className="btn btn-outline-secondary  mr-3" onClick={(e) => this.handleReset(e, 'allergies')}>CLEAR</Button>
									<Button form="allergyForm" type="primary" htmlType="submit" className="px-4" disabled={this.state.spin_loading || this.state.showAddAllergyButton}>
										SAVE{this.state.spin_loading ? LOADER : ""}
									</Button>
								</div>,
								<div className="text-center mt-3">
									{this.state.allergy_message ?
										<span style={{ color: '#548f3a' }}>Allergy Added Successfully</span>
										:
										null
									}
									{this.state.showAddAllergyButton ?
										<span style={{ color: '#ff0000' }}>Click add button to save the allergy</span>
										:
										null
									}
								</div>,
							]}
						>
							<div className="row">
								<div className="col-12">
									<Form
										id="allergyForm"
										ref={this.allergyFormRef}
										validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
										onFinish={this.handleAllergyAddSubmit}
										layout="vertical"
									>
										{!formModeEdit ? (
											<ul className="nav nav-tabs">
												<li className="nav-item">
													<a
														className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? "active" : ""}`}
														onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[0])}
													>
														Drugs
													</a>
												</li>
												<li className="nav-item">
													<a
														className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? "active" : ""}`}
														onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[1])}
													>
														Food
													</a>
												</li>
												<li className="nav-item">
													<a
														className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? "active" : ""}`}
														onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[2])}
													>
														Others
													</a>
												</li>
											</ul>
										) : (
											""
										)}

										{this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? (
											<div className="antSelectItem mt-4">
												<div className="form-group add_buttons font-col">
													{(this.state.showAddAllergyButton) ?
														<a className={"btn btn-outline-primary"} onClick={() => this.handleAddButton("showAddAllergyButton")}>ADD</a>
														:
														null
													}
													<Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true ,whitespace: true,message:'Drugs can not be empty'}]}>
														<AutoComplete
															id="allergy_selected"
															name="allergy_selected"
															autoFocus
															showSearch
															showArrow={!this.state.showAddAllergyButton}
															value={allergy}
															notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
															loading={this.state.formIsFetching}
															filterOption={false}
															onSearch={this.fetchAllergyData}
															onSelect={this.handleOnAllergyChange}
															style={{ width: "100%" }}
															disabled={this.state.formModeEdit}
														>
															{allergyList !== undefined && allergyList.length > 0
																? allergyList.map((d) => (
																	<Option key={d.id} value={d.short} dataid={d} label={d.code}>
																		{d.long}
																	</Option>
																))
																: null}
														</AutoComplete>
													</Form.Item>
												</div>
											</div>
										) : null}

										{this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? (
											<div className="antSelectItem mt-4">
												<div className="form-group add_buttons font-col">
													{(this.state.showAddAllergyButton) ?
														<a className={"btn btn-outline-primary"} onClick={() => this.handleAddButton("showAddAllergyButton")}>ADD</a>
														:
														null
													}
													<Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true ,whitespace: true ,message:'Food can not be empty'}]}>
														<AutoComplete
															id="allergy_selected"
															name="allergy_selected"
															showSearch
															autoFocus
															defaultOpen
															showArrow={!this.state.showAddAllergyButton}
															value={allergy_selected}
															optionFilterProp="children"
															notFoundContent={null}
															filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
															onSearch={this.fetchAllergyFoodData}
															onSelect={this.handleOnAllergyChange}
															style={{ width: "100%" }}
															disabled={this.state.formModeEdit}
														>
															{allergy_food !== undefined && allergy_food.length > 0
																? allergy_food.map((d) => (
																	<Option key={d.id} value={d.short} dataid={d} label={d.code}>
																		{d.long}
																	</Option>
																))
																: null}
														</AutoComplete>
													</Form.Item>
												</div>
											</div>
										) : null}

										{this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? (
											<div className="antSelectItem mt-4">
												<div className="form-group add_buttons font-col">
													{(this.state.showAddAllergyButton) ?
														<a className={"btn btn-outline-primary"} onClick={() => this.handleAddButton("showAddAllergyButton")}>ADD</a>
														:
														null
													}
													<Form.Item name="allergy_selected" label={this.state.allergy_active_tab.toLowerCase()} rules={[{ required: true ,whitespace: true,message:'Others can not be empty'}]}>
														<AutoComplete
															id="allergy_selected"
															name="allergy_selected"
															showSearch
															autoFocus
															defaultOpen
															showArrow={!this.state.showAddAllergyButton}
															value={allergy_selected}
															optionFilterProp="children"
															notFoundContent={null}
															filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
															onSearch={this.fetchAllergyOthersData}
															onSelect={this.handleOnAllergyChange}
															style={{ width: "100%" }}
															disabled={this.state.formModeEdit}
														>
															{allergy_other !== undefined && allergy_other.length > 0
																? allergy_other.map((d) => (
																	<Option key={d.id} value={d.short} dataid={d} label={d.code}>
																		{d.long}
																	</Option>
																))
																: null}
														</AutoComplete>
													</Form.Item>
												</div>
											</div>
										) : null}

										<div className="antSelectItem mt-4">
											<Form.Item
												name="reaction"
												onChange={(e) => this.handleOnReactionChange(e)}
												label="Reaction"
											// rules={[
											//   {
											//     required: true,
											//   },
											// ]}
											>
												<Select mode="tags" style={{ width: "100%" }} dropdownClassName="d-none"></Select>
											</Form.Item>
										</div>
										<Form.Item
											name="intensity"
											className="mt-2"
											label={<strong>Intensity </strong>}
											rules={[
												{
													required: false,
													message: "Please choose one!",
												},
											]}
										>
											<Radio.Group>
												<Radio value="0" className="mr-4">
													Low <i className="icon-severity-low"></i>
												</Radio>
												<Radio value="1" className="mr-4">
													Moderate <i className="icon-severity-medium"></i>
												</Radio>
												<Radio value="2">
													High <i className="icon-severity-high"></i>
												</Radio>
											</Radio.Group>
										</Form.Item>

										<div className="antInputItem">
											<Form.Item
												name="allergy_duration"
												label="Duration"
												className="onlynumbers"
												rules={[
													{
														required: false,
														type: "number",
														min: 0,
														max: this.handleMaxLimit(this.state.allergyDurationType),
														transform: function (value) {
															return value === "" || value === undefined ? value : +value;
														},
													},
												]}
											>
												<Input maxLength="2" autoComplete="off" name="duration" onChange={this.handleOnChange} />
											</Form.Item>
										</div>
										<Form.Item
											name="allergyDurationType"
											rules={[
												{
													required: this.state.allergy_durationType_required,
													message: "Please choose one!",
												},
											]}
										>
											<Radio.Group
												onChange={(e) => {
													this.radioChange(e, "allergyDurationType");
													this.allergyFormRef.current.validateFields();
												}}
											>
												<Radio value="0">Days ago</Radio>
												<Radio value="1">Weeks ago</Radio>
												<Radio value="2">Months ago</Radio>
												<Radio value="3">Years ago</Radio>
											</Radio.Group>
										</Form.Item>

										<Form.Item
											name="informedby"
											className="mt-3"
											label={<strong>Informed by? </strong>}
											rules={[
												{
													required: true,
													message: "Please choose one!",
												},
											]}
										>
											<Radio.Group>
												<Radio value="0" className="mr-4">
													{INFORMED_BY[0]}
												</Radio>
												<Radio value="1">{INFORMED_BY[1]}</Radio>
											</Radio.Group>
										</Form.Item>
										{/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
									</Form>
								</div>
							</div>
						</Modal>
					) : null}

					{/* Inactive Allergy */}
					<Modal
						title={"Inactive Allergy"}
						visible={this.state.cancelledAllergy}
						onCancel={() => this.setState({ cancelledAllergy: false })}
						footer={false}
						width={'75%'}
					>
						<div className="bg-white overflow-auto" >
							<table className="table table-bordered" >
								<tbody>
									{patientInactiveAllergies.length > 0 ?
										<tr>
											<th width="200">Allergen</th>
											<th width="200">Reaction</th>
											<th width="120">Severity</th>
											<th width="200">Source</th>
											<th width="200">Inactive By</th>
											<th width="300">Inactive At</th>
										</tr>
										: ''}
									{patientInactiveAllergies.length > 0 ? patientInactiveAllergies.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((allergy, index) => (
										allergy.deleted_at != null ?
											<tr>
												<td width="200">{allergy.allergy_name}</td>
												<td width="200">{allergy.reaction}</td>
												{allergy.intensity == null ? <td width="120"></td>
													: allergy.intensity === 0 ? <td width="120"><Tooltip placement="top" title='Low'><i className="icon-severity-low" /></Tooltip> </td>
														: allergy.intensity === 1 ? <td width="120"><Tooltip placement="top" title='Moderate'><i className="icon-severity-medium" /></Tooltip> </td>
															: <td width="120"><Tooltip placement="top" title='High'><i className="icon-severity-high" /> </Tooltip></td>
												}

												{/* {allergy.intensity == null ?
                                        <td width="120"></td>
                                        : <td width="120"><i className={`${allergy.intensity === 0 ? 'icon-severity-low' :
                                          allergy.intensity === 1 ? 'icon-severity-medium' : 'icon-severity-high'}`} /> </td>
                                      } */}
												<td width="200">{INFORMED_BY[allergy.informedBy]}</td>
												<td width="200">{allergy.deletedBy}</td>
												<td width="300">{moment(allergy.deleted_at).format("DD MMM YYYY | hh:mm A")}</td>
											</tr> : null
									)) : <tr><td colSpan="5">No results found</td></tr>}
								</tbody>
							</table>
						</div>
					</Modal>

					{/* Inactive Existing Conditions */}
					<Modal
						title={"Inactive Existing Conditions"}
						visible={this.state.cancelledExistingConditions}
						onCancel={() => this.setState({ cancelledExistingConditions: false })}
						footer={false}
						width={'75%'}
					>
						<div className="bg-white overflow-auto">
							<table className="table table-bordered" >

								<tbody>
									{typeof this.state.patientInactiveConditions !== 'undefined' && Object.keys(this.state.patientInactiveConditions).length > 0
										?
										<tr>
											<th width="120">Condition</th>
											<th width="120">Duration</th>
											<th width="120">Medication</th>
											<th width="120" style={{ wordBreak: 'break-word' }}>Medicine Name</th>
											<th width="120" >Inactive By</th>
											<th width="120" >Inactive At</th>
										</tr>
										: ''
									}
									{typeof this.state.patientInactiveConditions !== 'undefined' && Object.keys(this.state.patientInactiveConditions).length > 0 ?
										this.state.patientInactiveConditions.map(ConditionObj =>
											ConditionObj.deleted_at != null ?
												<tr>
													<td width="120">{ConditionObj.condition_name}</td>
													<td width="120">{ConditionObj.duration} {this.state.existingConditionDurationData[ConditionObj.durationType]}</td>
													<td width="120">{ConditionObj.onMedication === 1 ? "Yes" : "No"}</td>
													<td width="120">{ConditionObj.medicineName}</td>
													<td width="120">{ConditionObj.deletedBy}</td>
													<td width="200">{moment(ConditionObj.deleted_at).format("DD MMM YYYY | hh:mm A")}</td>
												</tr> : null
										)
										: <tr><td colSpan="5">No results found</td></tr>}
								</tbody>

							</table>
						</div>
					</Modal>

					{/* Inactive Surgical History */}
					<Modal
						title={"Inactive Surgical History"}
						visible={this.state.cancelledSurgicalHistory}
						onCancel={() => this.setState({ cancelledSurgicalHistory: false })}
						footer={false}
						width={'75%'}
					>
						<div className="bg-white overflow-auto">
							<table className="table table-bordered " >

								<tbody >
									{typeof this.state.inactiveSurgicalHistory !== 'undefined' && Object.keys(this.state.inactiveSurgicalHistory).length > 0
										?
										<tr>
											<th style={{ width: "35%" }}>Surgery Name</th>
											<th style={{ width: "15%" }}>Date</th>
											<th style={{ width: "25%" }}>Inactive By</th>
											<th style={{ width: "25%" }}>Inactive At</th>
										</tr>
										: ''}
									{typeof this.state.inactiveSurgicalHistory !== 'undefined' && Object.keys(this.state.inactiveSurgicalHistory).length > 0 ?
										this.state.inactiveSurgicalHistory.map(surgicalObj =>
											surgicalObj.deleted_at != null ?
												<tr>
													<td style={{ width: "35%" }}>{surgicalObj.reason}</td>
													<td style={{ width: "15%" }}>{this.nowCurrentDate(surgicalObj.duration, surgicalObj.durationType)}</td>
													<td style={{ width: "25%" }}>{surgicalObj.deletedBy}</td>
													<td style={{ width: "25%" }}>{moment(surgicalObj.deleted_at).format("DD MMM YYYY | hh:mm A")}</td>
												</tr> : null
										)
										: <tr><td colSpan="3">No results found</td></tr>}
								</tbody>


							</table>
						</div>
					</Modal>

					{/* Inactive Social History */}
					<Modal
						title={"Inactive Social History"}
						visible={this.state.cancelledSocialHistory}
						onCancel={() => this.setState({ cancelledSocialHistory: false })}
						footer={false}
						width={'75%'}
					>
						<div className="bg-white overflow-auto">
							<table className="table  table-bordered">
								<tbody >
									
									{typeof this.state.socialHxInactiveData !== 'undefined' && Object.keys(this.state.socialHxInactiveData).length > 0 ?
									<>
									{headerHabitCancelled}
									{
										this.state.socialHxInactiveData.map((socialObj, index) =>
										socialObj.is_active == "1" ?
											(
												<tr style={{ background: 'white' }} id={`social_history_${socialObj.id}`} key={socialObj.id}>
													<td width='150'>{this.state.socialHxHabitData[socialObj.details] || ""}</td>
													<td width='150' className="habits_status">{this.state.socialHxStatusData[socialObj.options] || "NA"}</td>
													<td width='150' className="habits_duration">{socialObj.since} {this.state.socialHxSinceTimeData[socialObj.time] || "NA"}</td>
													<td width='160' className="habits_freq">{this.state.socialHxFrequencyData[socialObj.frequency] || "NA"}</td>
													<td width="110" className="habits_quant">{socialObj.quantity} {this.state.socialHxQuantityData[socialObj.quantityUnit] || "NA"}</td>
													<td width="160" className="habits_freq">{socialObj.inactiveBy}</td>
													<td width="160" className="habits_freq">{moment(socialObj.inactiveAt).format("DD MMM YYYY | hh:mm A")}</td>
												</tr>
											) : null)
									}
									</>
										 :<tr><td colSpan="5">No results found</td></tr>}
								</tbody>
							</table>

						</div>
					</Modal>

					{/* Inactive Family History */}
					<Modal
						title={"Inactive Family History"}
						visible={this.state.cancelledFamilyHistory}
						onCancel={() => this.setState({ cancelledFamilyHistory: false })}
						footer={false}
						width={'75%'}
					>
						<div className="bg-white overflow-auto">
							<table className="table  table-bordered" >

								<tbody >
									{typeof this.state.familyHistoryInactiveData !== 'undefined' && Object.keys(this.state.familyHistoryInactiveData).length > 0
										?
										<tr>
											<th width="480">Disease</th>
											<th width="250">Relation</th>
											<th width="250">Inactive By</th>
											<th width="250">Inactive At</th>
										</tr>
										: ''}
									{typeof this.state.familyHistoryInactiveData !== 'undefined' && Object.keys(this.state.familyHistoryInactiveData).length > 0
										?
										this.state.familyHistoryInactiveData.map(familyObj =>
												<tr>
													<td width="480">{familyObj.diseaseName}</td>
													<td width="250">{familyObj.relationshipName}</td>
													<td width="250">{familyObj.inactive_by}</td>
													<td width="250">{moment(familyObj.inactive_at).format("DD MMM YYYY | hh:mm A")}</td>
												</tr>
										)
										: <tr><td colSpan="3">No results found</td></tr>}
								</tbody>
							</table>

						</div>
					</Modal>
					{/* ************************* ALERGY MODEL POPUP FOR CIMS * ************************* */}
					<Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
					title="Allergy Interaction"
					visible={this.state.isModalInteractionVisible}
					onOk={() => this.handleInteractionOk()}
					onCancel={() => this.handleInteractionCancel()}
					footer={false}
					style={{width:1000}}
					zIndex={7776}
					>
					<iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_ALLERGY_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
					<div className="modal-footer justify-content-center emrfrm pb-0">
						<button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
						<button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ADD ALLERGY</button>
					</div>
					</Modal>


				</div>
			</div>
		);
	}
}

export default ObjectiveComponentSS;

